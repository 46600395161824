.InitiativeImages-thumbnail {
  cursor: pointer;
}
.ImagePreview-image {
  padding: 10px;
}
.ImagePreview-download {
  color: #7B8989;
}
.ImagePreview .ant-modal-body {
  padding: 0px;
}
