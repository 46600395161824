.newCourseName,
.newCourseCode {
  width: 100%;
  float: left;
}
.newCourseName span,
.newCourseCode span {
  float: left;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7B8989;
  margin-bottom: 6px;
}
.newCourseCode {
  margin-top: 12px;
  margin-bottom: 60px;
}
.saveNewCourse,
.cancelNewCourse {
  float: right;
  cursor: pointer;
}
.saveNewCourse {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  text-align: right;
  color: #117065;
  margin-left: 24px;
}
.cancelNewCourse {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  text-align: right;
  color: var(--secondary-text);
}
