.AuthorizedLink-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: #147F74;
  display: inline;
  margin: 0;
  padding: 0;
}
.AuthorizedLink-button:hover,
.AuthorizedLink-button:focus {
  text-decoration: none;
}
.AuthorizedLink-anchor {
  display: none !important;
}
