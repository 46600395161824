.backToActions {
  float: left;
  display: flex;
  align-items: center;
}
.backToActions img {
  float: left;
  margin-right: 8px;
}
.backToActions span {
  float: left;
  font-size: 29px;
  font-weight: 500;
  color: rgba(39, 57, 65, 0.7);
  text-align: left;
  letter-spacing: 0.01em;
  display: inline-block;
}
