.Proposal-heading {
  text-align: center;
}
.Proposal-accepted {
  font-weight: bold;
  color: #147F74;
}
.Proposal-rejected {
  font-weight: bold;
  color: #D65050;
}
.Proposal-rejected-reason {
  font-size: small;
  font-style: italic;
  color: #D65050;
}
.Proposal-rejected-reason::before {
  content: open-quote;
}
.Proposal-rejected-reason::after {
  content: close-quote;
}
.Proposal-tagline {
  line-height: 2;
}
.Proposal-sdgs {
  height: 40px;
  display: flex;
}
.Proposal-sdgs .SdgLogo {
  height: 40px;
  width: 40px;
  margin: 5px;
}
.noPending {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: #7B8989;
  margin-top: 38px;
  opacity: 0.5;
}
