.InitiativeCollaborator-or {
  text-transform: uppercase;
  font-size: larger;
  text-align: center;
  color: #147F74;
  margin: 0 auto;
}
.InitiativeCollaborator-validated {
  font-weight: bold;
  color: #147F74;
}
.InitiativeCollaborator-rejected {
  font-weight: bold;
  color: #D65050;
}
.InitiativeCollaborator-pendingcount {
  font-weight: bold;
  color: #D65050;
}
.InitiativeCollaborator-status-collaborating {
  font-weight: bold;
  color: #147F74;
}
.InitiativeCollaborator-status-pending {
  font-weight: bold;
  color: #D65050;
}
.InitiativeCollaborator-status-rejected {
  font-weight: bold;
  color: #D65050;
}
