.InitiativeCard {
  float: left;
  position: relative;
  height: 100%;
}
.InitiativeCard-participant-count {
  position: absolute;
  bottom: 30px;
}
.InitiativeCard-participant-count.hasSubmitFooter {
  bottom: 72px;
}
.InitiativeCard-participant-count svg {
  width: 15px;
  height: 15px;
}
.InitiativeCard-participant-count span {
  position: absolute;
  bottom: 4px;
  left: 18px;
  color: #273941;
  font-size: 13px;
}
.InitiativeCard-sdgs-popover {
  cursor: pointer;
}
.InitiativeCard-sdgs-popover-content {
  padding-bottom: 20px;
}
.InitiativeCard-sdgs-popover-content * {
  margin-right: 5px;
}
.InitiativeCard-tags {
  margin-top: 3px;
  margin-bottom: 3px;
  position: relative;
  overflow: hidden;
}
.InitiativeCard-tags:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  background-image: linear-gradient(90deg, transparent 0%, #FFF 100%);
  margin: 0px;
}
.InitiativeCard-tag {
  margin-right: 10px;
}
.InitiativeCard > .ant-card-cover {
  float: left;
  width: 100%;
}
.InitiativeCard-cover {
  background: #ddd;
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
.InitiativeCard-cover > img {
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.InitiativeCard-cover .InitiativeCard-date {
  position: absolute;
  background: #FFF;
  border-radius: 3px;
  bottom: -10px;
  left: 10px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
}
.InitiativeCard-cover .InitiativeCard-date.interval {
  width: 81px;
  height: 38px;
}
.InitiativeCard-cover .InitiativeCard-date.interval .dayStart {
  float: left;
  height: 100%;
  width: calc(50% - 4px);
}
.InitiativeCard-cover .InitiativeCard-date.interval .dayStart .day-start {
  color: #147F74;
  font-size: 19px;
  float: left;
  width: 100%;
  text-align: center;
}
.InitiativeCard-cover .InitiativeCard-date.interval .dayStart .month-start {
  float: left;
  color: #147F74;
  font-size: 11px;
  width: 100%;
  text-align: center;
  margin-top: -7px;
}
.InitiativeCard-cover .InitiativeCard-date.interval .divider {
  float: left;
  width: 8px;
  height: 2px;
  background: rgba(123, 137, 137, 0.3);
  margin: 18px 0;
}
.InitiativeCard-cover .InitiativeCard-date.interval .endDay {
  float: left;
  height: 100%;
  width: calc(50% - 4px);
}
.InitiativeCard-cover .InitiativeCard-date.interval .endDay .day-end {
  float: left;
  color: #7b8989;
  font-size: 19px;
  width: 100%;
  text-align: center;
}
.InitiativeCard-cover .InitiativeCard-date.interval .endDay .month-end {
  float: left;
  color: #7b8989;
  font-size: 11px;
  width: 100%;
  text-align: center;
  margin-top: -7px;
}
.InitiativeCard-cover .InitiativeCard-date.oneDay {
  width: 41px;
  height: 38px;
}
.InitiativeCard-cover .InitiativeCard-date.oneDay .day {
  color: #147F74;
  font-size: 19px;
  float: left;
  width: 100%;
  text-align: center;
}
.InitiativeCard-cover .InitiativeCard-date.oneDay .month {
  float: left;
  color: #147F74;
  font-size: 11px;
  width: 100%;
  text-align: center;
  margin-top: -7px;
}
.InitiativeCard-cover .InitiativeCard-date.canceled {
  width: auto;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-size: 15px;
  color: #ff4545;
  text-align: left;
  letter-spacing: 0.01em;
  font-weight: 500;
}
.InitiativeCard-cover .rightTags {
  position: absolute;
  right: 8px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.InitiativeCard-cover .rightTags.top {
  top: 8px;
}
.InitiativeCard-cover .rightTags.bottom {
  bottom: 8px;
}
.InitiativeCard-cover .leftTags {
  position: absolute;
  left: 8px;
  top: 8px;
  display: flex;
  max-width: calc(40% - 10px);
  align-items: flex-start;
  flex-direction: column;
}
.InitiativeCard-cover .leftTags .pinWrapper {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  color: #FFF;
  padding: 3px 5px;
}
.InitiativeCard-cover .leftTags .pinWrapper > span {
  color: white;
  font-size: 25px;
}
.InitiativeCard-cover .leftTags .programWrapper {
  padding: 0;
  height: 30px;
  background-color: #fff;
  position: relative;
  border-radius: 6px;
  color: #147F74;
  font-weight: bold;
  flex: 1;
  max-width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.InitiativeCard-cover .leftTags .programWrapper > span {
  box-sizing: border-box;
  height: 30px;
  width: 100%;
  padding: 3px 10px 3px 12px;
  background-color: rgba(20, 127, 116, 0.15);
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.InitiativeCard-cover .InitiativeCard-daysleft {
  margin-bottom: 3px;
  height: 30px;
  padding: 0 12px 0 10px;
  background-color: rgba(255, 169, 49, 0.9);
  border-radius: 6px;
  font-weight: bold;
  font-size: 15px;
  color: #FFF;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  width: fit-content;
}
.InitiativeCard-cover .InitiativeCard-submitter,
.InitiativeCard-cover .InitiativeCard-collaborator {
  margin-bottom: 3px;
  height: 30px;
  padding: 0 12px 0 10px;
  background-color: rgba(26, 26, 26, 0.9);
  border-radius: 6px;
  font-weight: bold;
  font-size: 15px;
  color: #FFF;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  width: fit-content;
}
.InitiativeCard-cover .InitiativeCard-submitter .submitter-avatar,
.InitiativeCard-cover .InitiativeCard-collaborator .submitter-avatar {
  margin-right: 8px;
}
.InitiativeCard-cover .InitiativeCard-submitter .submitter-name,
.InitiativeCard-cover .InitiativeCard-collaborator .submitter-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  margin-right: 8px;
}
.InitiativeCard-cover .InitiativeCard-passed {
  margin-bottom: 3px;
  height: 30px;
  padding: 0 12px 0 10px;
  background-color: rgba(123, 137, 137, 0.9);
  border-radius: 6px;
  font-weight: bold;
  font-size: 15px;
  color: #FFF;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  width: fit-content;
}
.InitiativeCard-cover .InitiativeCard-pending {
  margin-bottom: 3px;
  height: 30px;
  padding: 0 12px 0 10px;
  background-color: rgba(255, 68, 68, 0.9);
  border-radius: 6px;
  font-weight: bold;
  font-size: 15px;
  color: #FFF;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  width: fit-content;
}
.InitiativeCard-cover .InitiativeCard-inscriptionClosed {
  margin-bottom: 3px;
  height: 30px;
  padding: 0 12px 0 10px;
  background-color: rgba(26, 26, 26, 0.9);
  border-radius: 6px;
  font-weight: bold;
  font-size: 15px;
  color: #FFF;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  width: fit-content;
}
.InitiativeCard-cover .InitiativeCard-points {
  margin-bottom: 3px;
  height: 30px;
  padding: 0 12px 0 10px;
  background-color: rgba(236, 110, 37, 0.9);
  border-radius: 6px;
  font-weight: bold;
  font-size: 15px;
  color: #FFF;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  width: fit-content;
}
.InitiativeCard > .ant-card-body {
  float: left;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.InitiativeCard-body {
  width: 100%;
  height: 100%;
  padding: 12px 12px 50px 12px;
  float: left;
}
.InitiativeCard-body.hasSubmitFooter {
  padding: 12px 12px 100px 12px;
}
.InitiativeCard-body .InitiativeCard-title {
  font-size: 19px;
  font-weight: bold;
  margin-top: 5px;
  width: 100%;
  max-height: 70px;
  line-height: 23px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.InitiativeCard-body .InitiativeCard-title.notAddress {
  margin-bottom: 8px;
}
.InitiativeCard-body .InitiativeCard-address {
  float: left;
  height: 22px;
  font-size: 15px;
  color: #7b8989;
  text-align: left;
  letter-spacing: -0.01em;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 8px;
}
.InitiativeCard-body .InitiativeCard-donationInfo {
  margin-bottom: 8px;
}
.InitiativeCard-body .InitiativeCard-time {
  float: left;
  height: 22px;
  font-size: 13px;
  color: #7b8989;
  text-align: left;
  letter-spacing: -0.01em;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.InitiativeCard-body .InitiativeCard-sdgs {
  position: absolute;
  bottom: 0;
  right: 12px;
}
.InitiativeCard-body .InitiativeCard-sdgs.hasSubmitFooter {
  bottom: 42px;
}
.InitiativeCard-body .InitiativeCard-sdgs .sdgLogo {
  margin-right: 3px;
}
.InitiativeCard-body .InitiativeCard-type {
  position: absolute;
  bottom: 13px;
  left: 12px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.InitiativeCard-body .InitiativeCard-type.hasSubmitFooter {
  bottom: 55px;
}
.InitiativeCard-body .InitiativeCard-type .InitiativeCard-type-text {
  float: right;
  font-size: 13px;
  letter-spacing: 0.17px;
  color: #273941;
}
.InitiativeCard-body .InitiativeCard-type .InitiativeCard-type-img {
  float: right;
  width: 15px;
  height: 15px;
  margin-right: 7px;
}
.InitiativeCard-body .InitiativeCard-type .InitiativeCard-collaborativeFooter {
  margin-right: 8px;
}
.InitiativeCard-body .donationProgressBar .ant-progress-inner {
  height: 6px;
}
.InitiativeCard-body .donationProgressBar .ant-progress-inner .ant-progress-bg {
  height: 6px !important;
}
.InitiativeCard-submit {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  float: left;
  background-color: rgba(123, 137, 137, 0.07);
  border-radius: 0px 0px 6px 6px;
}
.InitiativeCard-submit .rejected-img {
  float: right;
  margin: 14px 0px;
}
.InitiativeCard-submit .accepted-img {
  float: right;
  margin: 15px 0px;
}
.InitiativeCard-submit .pending-img,
.InitiativeCard-submit .collaborating-img {
  float: right;
  margin: 12px 0px;
}
.InitiativeCard-submit .submit-accepted,
.InitiativeCard-submit .submit-collaborating {
  float: right;
  font-size: 15px;
  text-align: right;
  font-weight: 500;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 12px 5px 5px;
  color: #00b46e;
}
.InitiativeCard-submit .submit-rejected {
  float: right;
  font-size: 15px;
  text-align: right;
  font-weight: 500;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 12px 5px 5px;
  color: #ff4545;
}
.InitiativeCard-submit .submit-pending {
  float: right;
  font-size: 15px;
  text-align: right;
  font-weight: 500;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 12px 5px 5px;
  color: #ff9500;
}
.InitiativeCard-submit .submit-reject {
  float: right;
  font-size: 15px;
  color: #7b8989;
  text-align: right;
  font-weight: 500;
  height: 32px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0px;
  cursor: pointer;
}
.InitiativeCard-submit .submit-review,
.InitiativeCard-submit .submit-collaborate {
  float: right;
  height: 32px;
  padding: 0 15px;
  background-color: #ffffff;
  border: 2px solid #e5e8e8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 5px;
  font-size: 15px;
  color: #273941;
  text-align: left;
  font-weight: 500;
  cursor: pointer;
}
