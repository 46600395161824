@import '~antd/dist/antd.css';
:root {
  --primary-color: #147F74;
}
/* --- GENERAL --- */
* {
  font-family: Roboto, sans-serif;
}
body {
  background-color: #FCFCFC !important;
}
h1 {
  font-size: 51px;
  font-weight: bold !important;
  color: #273941 !important;
  margin: 0px !important;
  line-height: 60px;
}
h2 {
  font-size: 21px;
  color: #273941 !important;
}
h3 {
  font-size: 17px;
  color: #273941 !important;
}
h4 {
  font-size: 17px;
  color: #273941 !important;
}
.App {
  text-align: center;
}
.Layout {
  min-height: 100vh !important;
  background: #FCFCFC !important;
}
.Header {
  background-origin: padding-box;
  position: fixed;
  width: 100%;
  z-index: 50;
}
.Content {
  display: flex;
  flex-direction: column;
  margin-top: 66px;
  padding: 0 40px 20px;
  background: #FCFCFC;
}
.Content-noheader {
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  background: #FCFCFC;
}
.Whitespace {
  height: 50px;
}
.Whitespace20 {
  height: 20px;
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .Content {
    width: 1200px;
    margin: 66px auto 0;
  }
}
/* --- Header --- */
.ant-layout-header.ant-layout-header {
  padding: 28px 30px 28px;
  width: auto;
  height: auto;
}
.ant-layout-has-sider > .ant-layout {
  padding: 0px 30px 120px 30px !important;
  margin-top: -6px;
  overflow-x: initial !important;
  max-width: calc(100% - 260px);
}
.ant-layout-sider.ant-layout-sider-light {
  flex: initial !important;
  max-width: initial !important;
  min-width: initial !important;
  width: initial !important;
  z-index: 2;
}
/* ------ */
/* --- Tags --- */
.ant-tag.ant-tag {
  border: 0px;
  font-size: 13px;
  padding: 4px 10px;
  font-weight: 500;
  letter-spacing: 0.02em;
}
.ant-tag-lime {
  background-color: ;
  color: ;
}
.ant-tag-green {
  background-color: ;
  color: ;
}
.ant-tag-cyan {
  background-color: ;
  color: ;
}
/* ------ */
/* --- Cards --- */
.ant-card.ant-card {
  border: none;
  box-shadow: 1px 0 0px 0px rgba(123, 137, 137, 0.1), 0 1px 0px 0px rgba(123, 137, 137, 0.1), 0 -1px 0px 0px rgba(123, 137, 137, 0.1), -1px 0px 0px 0px rgba(123, 137, 137, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}
.ant-card-head-title {
  font-size: 16px;
}
/* ------ */
/* --- Tables & dropdowns --- */
.ant-table-filter-dropdown-link {
  padding-left: 10px;
  padding-right: 10px;
}
.ant-table.ant-table {
  box-shadow: 1px 0 0px 0px rgba(123, 137, 137, 0.1), 0 1px 0px 0px rgba(123, 137, 137, 0.1), 0 -1px 0px 0px rgba(123, 137, 137, 0.1), -1px 0px 0px 0px rgba(123, 137, 137, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
.ant-table-thead .anticon-filter,
.ant-table-column-sorter-inner {
  color: #273941 !important;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item:hover {
  background: #f2f4f5;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
  color: #FFF;
  background: #E5E8E8 !important;
}
/* ------ */
/* --- Inputs --- */
.ant-input-search-enter-button .ant-input {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.ant-input.ant-input {
  border-radius: 6px;
  border-width: 2px;
}
.ant-input.ant-input:focus {
  border-color: #147F74 !important;
  box-shadow: none;
  border-width: 2px !important;
}
.ant-input.ant-input:hover {
  border-color: #CAD0D0;
  border-width: 2px !important;
}
textarea.ant-input.ant-input {
  border-radius: 6px;
}
.ant-select-selection.ant-select-selection {
  border-radius: 6px;
  border-width: 2px;
}
.ant-select-selection.ant-select-selection:focus,
.ant-select-selection.ant-select-selection:active {
  border-color: #147F74 !important;
  box-shadow: none;
  border-width: 2px !important;
}
.ant-select-selection.ant-select-selection:hover {
  border-color: #CAD0D0;
  border-width: 2px !important;
}
.ant-time-picker-input.ant-time-picker-input {
  border-radius: 6px;
}
.ant-input-number.ant-input-number {
  border-radius: 6px;
  border-width: 2px;
  border-color: #E5E8E8;
}
.ant-input-number.ant-input-number:focus,
.ant-input-number.ant-input-number.ant-input-number-focused {
  border-color: #147F74 !important;
  box-shadow: none;
  border-width: 2px !important;
}
.ant-input-number.ant-input-number:hover {
  border-color: #CAD0D0;
  border-width: 2px !important;
}
.ant-input-number.ant-input-number .ant-input-number-handler-wrap {
  background: transparent;
  border: 0px;
  right: 3px;
}
.ant-input-number.ant-input-number .ant-input-number-handler-down {
  border: 0px;
}
.ant-picker:hover,
.ant-select-selector:hover,
.ant-input-search.ant-input-search-enter-button:hover {
  border-color: #147F74;
}
.ant-picker:focus-within,
.ant-select-selector:focus-within,
.ant-input-search.ant-input-search-enter-button:focus-within {
  box-shadow: none;
  border-width: 2px;
}
/* ------ */
/* --- Tabs --- */
.ant-tabs.ant-tabs {
  overflow: visible;
}
.ant-tabs-tabpane {
  display: flex;
  flex-direction: column;
}
.ant-tabs-nav .ant-tabs-tab-active.ant-tabs-tab-active {
  font-weight: 700;
  letter-spacing: 0.02em;
}
/* ------ */
/* --- Pagination --- */
.ant-pagination-item.ant-pagination-item {
  border-radius: 6px;
  box-shadow: 1px 0 0px 0px rgba(123, 137, 137, 0.1), 0 1px 0px 0px rgba(123, 137, 137, 0.1), 0 -1px 0px 0px rgba(123, 137, 137, 0.1), -1px 0px 0px 0px rgba(123, 137, 137, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  border-color: #FFF;
}
.ant-pagination-item.ant-pagination-item a {
  background-color: #FFF;
  color: #273941;
  border-radius: 3px;
}
.ant-pagination-item.ant-pagination-item:hover,
.ant-pagination-item.ant-pagination-item:focus {
  border-color: #E5E8E8;
}
.ant-pagination-item.ant-pagination-item:hover a,
.ant-pagination-item.ant-pagination-item:focus a {
  color: #273941;
  background-color: #E5E8E8;
}
.ant-pagination-item.ant-pagination-item-active {
  border-color: #147F74;
}
.ant-pagination-item.ant-pagination-item-active a {
  background-color: #147F74;
  color: #FFF;
}
.ant-pagination-item.ant-pagination-item-active:hover,
.ant-pagination-item.ant-pagination-item-active:focus {
  border-color: #147F74;
}
.ant-pagination-item.ant-pagination-item-active:hover a,
.ant-pagination-item.ant-pagination-item-active:focus a {
  background-color: #147F74;
  color: #FFF;
}
.ant-pagination-next.ant-pagination-next,
.ant-pagination-prev.ant-pagination-prev {
  box-shadow: 1px 0 0px 0px rgba(123, 137, 137, 0.1), 0 1px 0px 0px rgba(123, 137, 137, 0.1), 0 -1px 0px 0px rgba(123, 137, 137, 0.1), -1px 0px 0px 0px rgba(123, 137, 137, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
.ant-pagination-next.ant-pagination-next a,
.ant-pagination-prev.ant-pagination-prev a {
  background-color: #FFF;
  color: #273941;
  border-radius: 6px;
  border-color: #FFF;
}
.ant-pagination-next.ant-pagination-next:hover a,
.ant-pagination-prev.ant-pagination-prev:hover a {
  background-color: #E5E8E8;
  border-color: #E5E8E8;
  color: #273941;
}
.ant-pagination-disabled.ant-pagination-disabled.ant-pagination-disabled {
  box-shadow: 1px 0 0px 0px rgba(123, 137, 137, 0.1), 0 1px 0px 0px rgba(123, 137, 137, 0.1), 0 -1px 0px 0px rgba(123, 137, 137, 0.1), -1px 0px 0px 0px rgba(123, 137, 137, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
.ant-pagination-disabled.ant-pagination-disabled.ant-pagination-disabled a {
  border: none;
  background-color: #FCFCFC;
  color: #273941;
}
.ant-pagination-disabled.ant-pagination-disabled.ant-pagination-disabled:hover {
  border-color: #FCFCFC;
}
.ant-pagination-disabled.ant-pagination-disabled.ant-pagination-disabled:hover a {
  border: none;
  background-color: #FCFCFC;
  color: #273941;
}
.ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev {
  min-width: 32px;
}
/* ------ */
/* --- Checkbox --- */
.ant-checkbox-inner.ant-checkbox-inner {
  border-radius: 6px;
}
.ant-checkbox-inner.ant-checkbox-inner:hover {
  border-color: #147F74;
}
.ant-table-thead .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FFF;
}
.ant-table-thead .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #147F74;
}
.ant-table-thead .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: #273941;
}
.ant-table-thead .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  border-radius: 6px;
  width: 80%;
  height: 4px;
}
/* ------ */
/* --- Trees --- */
.ant-tree.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.ant-tree.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #147F74;
  color: #FFF;
}
/* ------ */
/* --- Progress --- */
.ant-progress-bg.ant-progress-bg {
  background-color: rgba(19, 134, 122, 0.3);
}
.ant-progress-success-bg.ant-progress-success-bg {
  background-color: #13867a;
}
/* ------ */
/* --- Empty states --- */
.ant-empty.ant-empty {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-empty.ant-empty .ant-empty-description {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.26px;
  color: #7B8989;
}
.ant-empty.ant-empty.big .ant-empty-image {
  height: 8vw;
}
.ant-empty.ant-empty.big .ant-empty-description {
  margin-left: 2vw;
  font-size: 2vw;
}
.ant-empty.ant-empty.small .ant-empty-image {
  height: 3vw;
}
.ant-empty.ant-empty.small .ant-empty-description {
  margin-left: 1vw;
  font-size: 1vw;
}
/* ------ */
/* --- Modal --- */
.ant-modal-content.ant-modal-content .ant-modal-header {
  padding: 24px 30px;
}
.ant-modal-content.ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 31px;
  font-weight: bold;
  letter-spacing: -0.12px;
  line-height: 1.1em;
  color: #273941;
}
.ant-modal-content.ant-modal-content .ant-modal-body {
  background-color: #fcfcfc;
  float: left;
  width: 100%;
}
.ant-modal-content.ant-modal-content .ant-modal-footer {
  float: left;
  width: 100%;
  border: 0px;
  background-color: #fcfcfc;
}
/* ------ */
.ant-picker-focused,
.ant-input:focus,
.ant-input-focused {
  box-shadow: none;
}
