.Volunteer-heading {
  text-align: center;
}
.Volunteer-or {
  text-transform: uppercase;
  font-size: larger;
  text-align: center;
  color: #147F74;
  margin: 0 auto;
}
.Volunteer-active {
  font-weight: bold;
  color: #147F74;
}
.Volunteer-blocked {
  font-weight: bold;
  color: #D65050;
}
.Volunteer-invited {
  font-weight: bold;
  color: #FFC600;
}
.Volunteer-tagline {
  line-height: 2;
}
.Volunteer-qrcode {
  display: flex;
  justify-content: center;
}
.Volunteer-taxonomy-filter {
  margin: 20px 5px 35px;
}
.Volunteer-taxonomy-filter:first-child {
  margin-top: 5px;
}
.Volunteer-taxonomy-filter:last-child {
  margin-bottom: 15px;
}
.Volunteer-taxonomy-filter-sdg-logo {
  height: 20px;
  width: 20px;
  display: inline-block;
  padding-top: 0;
  margin-top: 5px;
  margin-bottom: -5px;
  margin-right: 10px;
}
.Volunteer-taxonomy-filter .ant-checkbox-wrapper {
  margin-bottom: 5px;
}
.volunteersIcon {
  font-size: 40px;
  text-align: left;
  color: #147F74;
}
.volunteersTitle {
  margin: 0px !important;
}
.Volunteer__context_IconInline {
  display: inline-block;
  margin-right: 3px;
}
.manageUserGroupsIcon {
  font-size: 14px;
  margin-left: 3px;
}
.manageUsersGroupsIcon {
  font-size: 14px;
}
.groupItem {
  justify-content: space-between;
}
.ant-table-title {
  padding: 0px;
  height: 0px;
}
.addGroupButton {
  margin-left: 15px;
}
.typeIcon.admin {
  color: #FFC600;
  position: absolute;
  left: -15px;
  top: -6px;
}
.typeIcon-inline {
  color: #FFC600;
  display: inline-block;
  margin-right: 3px;
}
.typeIcon-img {
  max-height: 1em;
  margin-right: 3px;
}
.typeIcon-img-crown {
  max-height: 0.8em;
  margin-bottom: 0.3em;
}
.ant-table-row.blocked {
  opacity: 0.7;
}
.removeAllFromCohort {
  margin-left: 10px;
}
.removeCohort {
  border: 0px;
  color: #D65050;
  float: left;
  background: transparent;
  margin-left: 5px;
}
.removeCohort:hover,
.removeCohort:focus {
  color: #FB003F;
  background: transparent;
}
.editableCohort.editableCohort {
  float: left;
  margin-bottom: 0px;
  max-width: calc(100% - 56px);
}
.nMembersOnCohort {
  float: left;
  margin-right: 5px;
}
.cohortManagement {
  margin-bottom: 5px;
}
.newGroupButton {
  margin-left: 10px;
}
.blockButton.blockButton {
  color: #D65050;
  border-color: #D65050;
}
.blockButton.blockButton:hover,
.blockButton.blockButton:focus {
  color: #FB003F;
  border-color: #FB003F;
}
.unblockButton.unblockButton {
  color: #147F74;
  border-color: #147F74;
}
.unblockButton.unblockButton:hover,
.unblockButton.unblockButton:focus {
  color: #147F74;
  border-color: #147F74;
}
.Volunteer__tableButtons {
  flex-wrap: nowrap;
}
.Volunteer__groupTag {
  white-space: normal;
  margin: 3px;
}
.addOptionCard {
  max-width: 400px;
  margin-bottom: 24px;
  width: 50%;
}
.addOptionCard.byEmail {
  max-width: none;
  width: 100%;
}
.addOptionCard.templateOption .ant-card-body {
  display: grid;
}
.addOptionCard.templateOption .ant-card-body .uploadTemplate {
  margin: 10px 0;
}
.newVolunteerName,
.newVolunteerEmail,
.newVolunteerPhone,
.newVolunteerButton {
  margin-right: 5px;
}
.emailError {
  font-size: 11px;
}
.copyableLink {
  color: #147F74;
}
.newGroupRow {
  margin-top: 10px;
}
.cohortsList {
  max-height: 38vh;
  overflow-y: scroll;
  padding-left: 14px;
}
.cohortsList::-webkit-scrollbar-thumb {
  background: #eee;
  border-radius: 14px;
}
.cohortsList::-webkit-scrollbar {
  width: 0.7em;
  background: transparent;
}
.cohortsList::-moz-scrollbar-thumb {
  background: #eee;
  border-radius: 14px;
}
.cohortsList::-moz-scrollbar {
  width: 0.7em;
  background: transparent;
}
.volunteersHeader {
  position: relative;
}
.volunteersHeader .volunteersTitle {
  display: inline;
  float: left;
}
.volunteersHeader .volunteersSubtitle {
  float: left;
  display: inline;
  position: absolute;
  bottom: 0;
  margin-bottom: 6px;
  margin-left: 10px;
  opacity: 0.7;
}
.newVolunteerPhone input {
  padding-left: 11px !important;
}
.emailError {
  margin-top: 5px;
  position: absolute;
  top: -45px;
  left: 15px;
}
.CohortAssign-modal .ant-row,
.CohortAssign-modal .ant-table-wrapper {
  width: 100%;
}
.CohortAssign-modal .ant-input-affix-wrapper {
  border-radius: 6px;
  border-width: 2px;
}
.CohortAssign-modal .ant-input-affix-wrapper:focus-within {
  border-color: #147F74 !important;
  box-shadow: none;
  border-width: 2px !important;
}
.blockModal__switchSendEmailWrapper > label {
  cursor: pointer;
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.65);
}
.blockModal .ant-collapse .ant-collapse-header {
  width: fit-content;
  padding: 0;
}
