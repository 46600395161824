.FloatingButton.ant-btn {
  position: fixed;
  bottom: 45px;
  right: 30px;
  width: 64px;
  height: 64px;
  z-index: 2;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
  border: 0px;
}
.FloatingButton.ant-btn:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
  border: 0;
}
.FloatingButton.ant-btn:focus {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3), inset 0 2px 1px 0 rgba(255, 255, 255, 0.16);
  border: 0;
}
.FloatingButton.ant-btn img {
  width: 25px;
}
