.InitiativeParticipant-or {
  text-transform: uppercase;
  font-size: larger;
  text-align: center;
  color: #147F74;
  margin: 0 auto;
}
.InitiativeParticipant-validated {
  font-weight: bold;
  color: #147F74;
}
.InitiativeParticipant-rejected {
  font-weight: bold;
  color: #D65050;
}
.InitiativeParticipant-pendingcount {
  font-weight: bold;
  color: #D65050;
}
.InitiativeParticipant-status-participating {
  font-weight: bold;
  color: #FFC600;
}
.InitiativeParticipant-status-participated {
  font-weight: bold;
  color: #147F74;
}
.InitiativeParticipant-status-pending {
  font-weight: bold;
  color: #D65050;
}
.InitiativeParticipant-status-rejected {
  font-weight: bold;
  color: #D65050;
}
.InitiativeParticipant-status-notgoing {
  font-weight: bold;
  color: #D65050;
}
.InitiativeParticipant-managerstatus {
  font-weight: bold;
}
.InitiativeParticipant-managerstatus-not_needed {
  color: #147F74;
}
.InitiativeParticipant-managerstatus-accepted {
  color: #147F74;
}
.InitiativeParticipant-managerstatus-pending {
  color: #FFC600;
}
.InitiativeParticipant-managerstatus-rejected {
  color: #D65050;
}
.InitiativeParticipant-send-certificate {
  margin-top: 10px;
}
.alreadyParticipant {
  font-weight: bold;
  color: #147F74;
}
.rejectButton.rejectButton {
  color: #D65050;
  border-color: #D65050;
}
.rejectButton.rejectButton:hover,
.rejectButton.rejectButton:focus {
  color: #FB003F;
  border-color: #FB003F;
}
.unrejectButton.unrejectButton {
  color: #147F74;
  border-color: #147F74;
}
.unrejectButton.unrejectButton:hover,
.unrejectButton.unrejectButton:focus {
  color: #147F74;
  border-color: #147F74;
}
.tableTabsOwn {
  overflow: visible;
}
.timeRequest {
  width: 100%;
}
.acceptRequest {
  margin-right: 5px;
}
.manualTimeInput {
  width: 50px;
}
.cancelManualTime {
  color: #D65050;
}
.cancelManualTime:hover,
.cancelManualTime:focus {
  color: #FB003F;
}
.cancelManualPoints {
  color: #D65050;
}
.cancelManualPoints:hover,
.cancelManualPoints:focus {
  color: #FB003F;
}
.Participants-taxonomy-filter {
  margin: 20px 5px 35px;
}
.Participants-taxonomy-filter:first-child {
  margin-top: 5px;
}
.Participants-taxonomy-filter:last-child {
  margin-bottom: 15px;
}
.Participants-taxonomy-filter-sdg-logo {
  height: 20px;
  width: 20px;
  display: inline-block;
  padding-top: 0;
  margin-top: 5px;
  margin-bottom: -5px;
  margin-right: 10px;
}
.Participants-taxonomy-filter .ant-checkbox-wrapper {
  margin-bottom: 5px;
}
.addParticipantButton {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  z-index: 2;
  background: linear-gradient(180deg, #0EAF9F 0%, #0D6A60 100%);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15), inset 0 2px 1px 0 rgba(255, 255, 255, 0.15);
  border: 0px;
}
.addParticipantButton:hover {
  background-image: linear-gradient(180deg, #15D8C4 0%, #0E897C 100%);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3), inset 0 2px 1px 0 rgba(255, 255, 255, 0.15);
  border: 0;
}
.addParticipantButton:focus {
  background-image: linear-gradient(180deg, #147F74 0%, #147F74 100%);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.29), inset 0 2px 1px 0 rgba(255, 255, 255, 0.15);
  border: 0;
}
.addParticipantButton img {
  width: 25px;
}
.newParticipantName,
.newParticipantEmail,
.newParticipantPhone {
  margin-right: 10px;
}
.newParticipantName input,
.newParticipantEmail input,
.newParticipantPhone input {
  width: 180px;
}
.newParticipantPhone input {
  padding-left: 11px !important;
}
.emailError {
  margin-top: 5px;
  position: absolute;
  top: -45px;
  left: 15px;
}
.InitiativeParticipant__groupTag {
  white-space: normal;
  margin: 3px;
}
.InitiativeParticipant__contactColumn.ant-table-cell {
  width: 0;
  height: 0;
  padding: 0;
}
