/* add css styles here (optional) */

.container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  place-items: stretch;
  place-content: stretch;
  grid-auto-flow: row; /* row dense */
  flex-wrap: wrap;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .container {
    display: block;
  }
}
@media (min-width: 768px) {
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1200px) {
  .container {
    grid-template-columns: repeat(6, 1fr);
  }
}

.card {
  margin: 0;
  padding: 0;
  border: solid 1px #ccc;
  background: #fff;
  border-radius: 25px;
  box-sizing: border-box;
  grid-column: span 1;
  grid-row: span 1;
  display: flex;
  flex-direction: column;
}

.card.card_size_small {
  grid-column: span 1;
  grid-row: span 1;
}

.card.card_size_medium {
  grid-column: span 2;
  grid-row: span 2;
}

.card.card_size_large {
  grid-column: span 4;
  grid-row: span 3;
}

.card_title {
  flex: 0 1 auto;
  font-weight: bold;
  font-size: large;
  color: #666666;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 1em;
  align-items: flex-start;
}

.card_meta {
  flex: 0 1;
  padding: 3px 1em 15px;
  text-align: center;
  font-size: small;
  color: #666666;
}

.card_icon {
  color: #666666;
  margin-right: 5px;
}

.card_period {
  justify-self: flex-end;
  align-self: flex-start;
}

.period_box {
  background: #eee;
  border: solid 1px #666;
  border-radius: 6px;
  padding: 2px 5px;
  font-size: small;
}

.card_content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  padding: 0 1em 1em 0.5em;
}

.card_title h3 {
  margin: 0 5px;
  color: #444;
  font-size: 18px;
  flex: 1;
}

.card_footer {
  flex: 0 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #FAFAFA;
  box-sizing: border-box;
  min-height: 60px;
  border-radius: 0 0 25px 25px;
  border-top: solid 1px #ccc;
}

.card_download {
  margin: 5px 1em;
  border-radius: 50%;
  background: #fff;
  border: solid 2px #444;
  height: 32px;
  width: 32px;
}

.card_download:hover {
  border-color: #666;
  color: #666;
}

.nodata {
  color: #888888;
  font-size: 32px;
  text-align: center;
  width: 100%;
  margin: 1em 0;
}

.singlenumber {
  text-align: center;
  width: 100%;
  margin: 1em 0;
}

.singlenumber output {
  color: #555;
  font-weight: bolder;
  font-size: 48px;
}

.singlenumber .compare_equal {
  color: #888888;
}

.singlenumber .compare_greater {
  color: green;
}

.singlenumber .compare_lower {
  color: red;
}

.verticalBars {
  height: 20vw;
  width: 100%;
}

.horizontalBars {
  height: 30vw;
  width: 100%;
}

.pie {
  height: 25vw;
  width: 100%;
}

.lines {
  height: 20vw;
  width: 100%;
}

