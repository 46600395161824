.FullScreenNoticeLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}
.FullScreenNoticeLayout__container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  margin: -120px 0 0 0;
  padding: 120px 0 0 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  align-items: stretch;
  flex: 1;
}
.FullScreenNoticeLayout__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 50%;
  min-width: 50%;
  padding: 45px 90px 0 90px;
}
.FullScreenNoticeLayout__content > * {
  max-width: 100%;
}
.FullScreenNoticeLayout__message {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.FullScreenNoticeLayout__footer {
  flex: 0 1;
  margin-bottom: 45px;
}
.FullScreenNoticeLayout__cover {
  display: none;
}
.FullScreenNoticeLayout__cover img {
  object-fit: contain;
  max-height: 90%;
  max-width: 90%;
}
@media all and (min-width: 993px) {
  .FullScreenNoticeLayout__cover {
    display: flex;
    flex: 50%;
    height: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
