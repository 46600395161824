.InitiativeContributors-or {
  text-transform: uppercase;
  font-size: larger;
  text-align: center;
  color: #147F74;
  margin: 0 auto;
}
.InitiativeContributors-validated {
  font-weight: bold;
  color: #147F74;
}
.InitiativeContributors-rejected {
  font-weight: bold;
  color: #D65050;
}
.InitiativeContributors-pendingcount {
  font-weight: bold;
  color: #D65050;
}
.InitiativeContributors-status-validated {
  font-weight: bold;
  color: #147F74;
}
.InitiativeContributors-status-pending {
  font-weight: bold;
  color: #D65050;
}
.InitiativeContributors-status-rejected {
  font-weight: bold;
  color: #D65050;
}
.InitiativeContributors__actions {
  display: flex;
  white-space: nowrap;
  justify-content: center;
}
.InitiativeContributors__modal {
  min-width: 60vw;
}
.InitiativeContributors__modal_input_number {
  margin-bottom: 15px;
  display: block;
  width: 100%;
  padding: 5px 0;
  box-sizing: content-box;
}
.InitiativeContributors__modal_input_number.ant-input-number-lg {
  font-size: 32px;
}
.InitiativeContributors__modal_input_number input {
  text-align: center;
}
.InitiativeContributors__modal_source_select {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.InitiativeContributors__modal_comment {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.InitiativeContributors__modal_anonymous {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.alreadyParticipant {
  font-weight: bold;
  color: #147F74;
}
.rejectButton.rejectButton {
  color: #D65050;
  border-color: #D65050;
}
.rejectButton.rejectButton:hover,
.rejectButton.rejectButton:focus {
  color: #FB003F;
  border-color: #FB003F;
}
.tableTabsOwn {
  overflow: visible;
}
.timeRequest {
  width: 100%;
}
.acceptRequest {
  margin-right: 5px;
}
.manualTimeInput {
  width: 50px;
}
.cancelManualTime {
  color: #D65050;
}
.cancelManualTime:hover,
.cancelManualTime:focus {
  color: #FB003F;
}
.Participants-taxonomy-filter {
  margin: 20px 5px 35px;
}
.Participants-taxonomy-filter:first-child {
  margin-top: 5px;
}
.Participants-taxonomy-filter:last-child {
  margin-bottom: 15px;
}
.Participants-taxonomy-filter-sdg-logo {
  height: 20px;
  width: 20px;
  display: inline-block;
  padding-top: 0;
  margin-top: 5px;
  margin-bottom: -5px;
  margin-right: 10px;
}
.Participants-taxonomy-filter .ant-checkbox-wrapper {
  margin-bottom: 5px;
}
.addContributionButton {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  z-index: 2;
}
.addContributionButton .anticon.anticon-user-add {
  font-size: 32px;
}
.newParticipantName,
.newParticipantEmail,
.newParticipantPhone {
  margin-right: 10px;
}
.newParticipantName input,
.newParticipantEmail input,
.newParticipantPhone input {
  width: 180px;
}
.newParticipantPhone input {
  padding-left: 11px !important;
}
.emailError {
  margin-top: 5px;
  position: absolute;
  top: -45px;
  left: 15px;
}
.InitiativeContributors__contactColumn.ant-table-cell {
  width: 0;
  height: 0;
  padding: 0;
}
