.InitiativeTypeSelector-input {
  width: 630px;
  float: left;
  margin-top: 71px;
  margin-right: calc(100% - 630px);
}
.InitiativeTypeSelector-input .ant-input {
  background: transparent;
  border: 0px;
  border-bottom: 2px solid black;
  border-radius: 0 !important;
  font-size: 45px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  height: 52px;
  color: #273941;
  border-color: rgba(39, 57, 65, 0.2);
  width: 100%;
}
.InitiativeTypeSelector-input .ant-input:focus {
  border-color: rgba(39, 57, 65, 0.2) !important;
}
.InitiativeTypeSelector-input span {
  float: right;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #7b8989;
  margin-top: 8px;
}
.InitiativeTypeSelector-types {
  width: 100%;
  float: left;
  margin-top: 71px;
  margin-bottom: 20px;
  display: flex;
}
.InitiativeTypeSelector-types .InitiativeTypeSelector-type {
  width: 250px;
  height: 250px;
  border-radius: 12px;
  border: solid 3px rgba(123, 137, 137, 0.2);
  background-color: #ffffff;
  margin-right: 54px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.InitiativeTypeSelector-types .InitiativeTypeSelector-type.active {
  border-color: #147F74;
}
.InitiativeTypeSelector-types .InitiativeTypeSelector-type.active.volunteering svg g,
.InitiativeTypeSelector-types .InitiativeTypeSelector-type.active.internal svg g,
.InitiativeTypeSelector-types .InitiativeTypeSelector-type.active.informative svg g,
.InitiativeTypeSelector-types .InitiativeTypeSelector-type.active.training svg path,
.InitiativeTypeSelector-types .InitiativeTypeSelector-type.active.collection svg path {
  fill: var(--primary-color);
}
.InitiativeTypeSelector-types .InitiativeTypeSelector-type.active.donation .iconFundraise {
  fill: var(--primary-color);
}
.InitiativeTypeSelector-types .InitiativeTypeSelector-type svg {
  height: 75px;
  width: 75px;
}
.InitiativeTypeSelector-types .InitiativeTypeSelector-type span {
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7b8989;
  margin-top: 21px;
}
.InitiativeTypeSelector-types .InitiativeTypeSelector-type.active span {
  color: #147F74;
  font-weight: 500;
}
.InitiativeTypeSelector-submit {
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-left: auto;
  order: 2;
}
