.NotSubscribed__title {
  font-size: 41px;
  font-weight: bold;
}
.NotSubscribed__desc {
  font-size: 19px;
  color: #7B8989;
}
.NotSubscribed__buttons {
  margin-top: 25px;
}
