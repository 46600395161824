.SelectableCard {
  min-height: 280px;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1 1;
}
.SelectableCard__container {
  display: flex;
  align-items: stretch;
}
.SelectableCard__container > *:not(:first-child) {
  margin-left: 20px;
}
.SelectableCard__title {
  line-height: 1.1em;
}
.SelectableCard__image {
  background: #ddd;
  overflow: hidden;
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 40%;
}
.SelectableCard__image > img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}
.SelectableCard__icon {
  margin-bottom: 15px;
  font-size: 50px;
}
.SelectableCard__icon img {
  filter: grayscale(100%);
}
.SelectableCard .ant-card-head {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.SelectableCard:hover {
  background: #E5E8E8;
}
.SelectableCard.active {
  box-shadow: 0 0 0 2px #147F74;
  border-color: #147F74;
  background: transparent;
}
.SelectableCard.active .ant-card-head {
  background: #147F74;
  color: #FFF;
  font-weight: bold;
}
.SelectableCard.active .SelectableCard__icon {
  color: #147F74;
}
.SelectableCard.active .SelectableCard__icon img {
  filter: none;
}
.SelectableCard > .ant-card-cover {
  flex: 0 1;
}
.SelectableCard > .ant-card-body {
  flex: 1;
}
@media all and (max-width: 769px) {
  .SelectableCard {
    margin-bottom: 10px;
  }
}
