.UserProfileModal {
  max-width: 80%;
  margin-top: 5vh;
  margin-bottom: 5vh;
  border-radius: 12px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px rgba(123, 137, 137, 0.1);
}
.UserProfileModal .ant-modal-header.ant-modal-header {
  background: #169C8E;
  padding-bottom: 140px;
  display: flex;
  margin: -24px;
  margin-bottom: 1rem;
  color: #7B8989;
}
.UserProfileModal .ant-modal-header.ant-modal-header .ant-modal-title.ant-modal-title {
  margin-right: 2rem;
  cursor: pointer;
}
.UserProfileModal .ant-modal-header.ant-modal-header .active {
  color: #FFF;
}
.UserProfileModal .ant-modal-close-x {
  color: #FFF;
}
.UserProfileModal .ant-modal-body {
  background-color: #FCFCFC;
  border-radius: 5px;
}
.UserProfileModal__avatar {
  display: block;
  margin-top: -90px;
  margin-bottom: 40px;
}
.UserProfileModal__avatar_row {
  display: flex;
  justify-content: center;
}
.UserProfileModal__manager_avatar {
  margin-left: 10px;
  margin-right: 5px;
}
.UserProfileModal__loading_row {
  display: flex;
  justify-content: center;
}
.UserProfileModal__bio_row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.UserProfileModal .ant-row-flex {
  margin-bottom: 30px;
}
.UserProfileModal__grid {
  margin-top: 30px;
  margin-bottom: 30px;
  column-count: 2;
  column-gap: 25px;
}
.UserProfileModal__grid > * {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
}
.UserProfileModal__card {
  background: #FFF;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(123, 137, 137, 0.1);
  padding: 18px;
}
.UserProfileModal__card .UserProfileModal__title {
  color: #7B8989;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 15px;
  display: flex;
  width: 100%;
}
.UserProfileModal__card .UserProfileModal__title__title {
  flex: 1;
  display: inline-block;
  margin-top: 3px;
}
.UserProfileModal__bignumber {
  font-size: 60px;
  font-weight: bolder;
  color: #273941;
}
.UserProfileModal__list {
  display: flex;
  flex-direction: column;
}
.UserProfileModal__list > * {
  flex: 1;
  flex-direction: row;
  display: flex;
}
.UserProfileModal__list > *:nth-child(even) {
  background-color: #ffffff;
  margin-left: -18px;
  padding-left: 18px;
  margin-right: -18px;
  padding-right: 18px;
}
.UserProfileModal__list > * > *:nth-child(odd) {
  font-weight: normal;
  height: 4em;
  flex: 0 70%;
  display: inline-flex;
  align-items: center;
}
.UserProfileModal__list > * > *:nth-child(even) {
  font-weight: bold;
  flex: 0 30%;
  height: 4em;
  color: #273941;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
}
.UserProfileModal__sdglogo {
  height: 46px;
  width: 46px;
  min-height: 46px;
  min-width: 46px;
  display: inline-block;
  padding-top: 0px;
  margin-right: 12px;
}
.UserProfileModal__download {
  display: flex;
  font-size: large;
  padding: 10px 65px;
  height: 50px;
}
.UserProfileModal__download_row {
  display: flex;
  justify-content: center;
}
.UserProfileModal__download > svg {
  margin-right: 10px;
}
.UserProfileModal__link {
  margin: 0;
  font-size: 17px;
}
.UserProfileModal__link > * {
  color: #7B8989;
  font-weight: bold;
}
.UserProfileModal__pullright {
  text-align: right;
}
.UserProfileModal__arrow {
  font-size: 32px;
  font-weight: bolder;
}
@media (max-width: 768px) {
  .UserProfileModal__grid {
    column-count: 1;
  }
}
