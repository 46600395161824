.ant-menu-vertical {
  border: none;
}
.ant-menu-item > a:hover {
  color: #273941;
}
.ant-menu-item.ant-menu-item-selected > a:hover {
  color: #147F74;
}
.ant-menu-vertical .ant-menu-item {
  padding-left: 20px;
  margin-left: 28px;
  padding-right: 26px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: fit-content;
}
.ant-menu-vertical .ant-menu-item > a {
  display: flex;
  align-items: center;
}
.ant-menu-vertical .ant-menu-item:hover {
  background: rgba(39, 57, 65, 0.07);
}
.ant-menu-vertical .ant-menu-item svg {
  float: left;
}
.ant-menu-vertical .ant-menu-item .badge {
  background-color: #ec3550;
  border-radius: 9px;
  margin: 0px 0px 0px 5px;
  line-height: 17px;
  font-size: 13px;
  color: #FFF;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  padding: 1px 8px;
}
.ant-menu-vertical .ant-menu-item.collaboration svg {
  transform: translate(0, 1px);
}
.ant-menu-vertical .ant-menu-item.proposal svg,
.ant-menu-vertical .ant-menu-item.volunteer svg,
.ant-menu-vertical .ant-menu-item.rankings svg,
.ant-menu-vertical .ant-menu-item.reporting svg {
  transform: translate(0, 0);
}
.ant-menu-vertical .ant-menu-item.initiative svg {
  transform: translate(0, 1px);
}
.ant-menu-vertical .ant-menu-item .navLabel,
.ant-menu-vertical .ant-menu-item .navIcon {
  font-size: 17px;
  float: left;
}
.ant-menu-vertical .ant-menu-item.ant-menu-item-selected {
  background-color: rgba(20, 127, 116, 0.15);
}
.ant-menu-vertical .ant-menu-item.ant-menu-item-selected .navLabel,
.ant-menu-vertical .ant-menu-item.ant-menu-item-selected .navIcon {
  font-size: 17px;
  font-weight: bold;
  color: #147F74;
}
.ant-menu-inline-collapsed .ant-menu-item {
  margin-left: 5px;
}
.about-our-program-icon svg {
  margin-right: 0px;
  height: 18px;
  width: 17px;
}
