.EmptySearch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 45px 0;
}
.EmptySearch__img {
  max-width: 200px;
  max-height: 150px;
  margin-bottom: 32px;
}
.EmptySearch__text {
  font-size: 16px;
  color: #5A6462;
}
