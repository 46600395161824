.addIndicatorButton {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  z-index: 2;
}
.addIndicatorButton .anticon.anticon-plus {
  font-size: 32px;
}
.colorRed,
.colorRed:hover,
.colorRed:focus {
  color: #ec3550;
}
