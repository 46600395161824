.sdgLogo {
  float: left;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #E5E8E8;
  color: #273941;
  font-size: 11px;
  letter-spacing: -0.01em;
  font-weight: bold;
  text-align: center;
  line-height: 19px;
  cursor: default;
}
.sdgLogo.no-poverty {
  background: rgba(229, 36, 59, 0.2);
  color: #E5243B;
}
.sdgLogo.zero-hunger {
  background: rgba(222, 167, 57, 0.2);
  color: #DEA739;
}
.sdgLogo.good-health-and-wellbeing {
  background: rgba(76, 161, 70, 0.2);
  color: #4CA146;
}
.sdgLogo.quality-education {
  background: rgba(197, 32, 45, 0.2);
  color: #C5202D;
}
.sdgLogo.gender-equality {
  background: rgba(239, 63, 42, 0.2);
  color: #EF3F2A;
}
.sdgLogo.clean-water-and-sanitation {
  background: rgba(39, 189, 226, 0.2);
  color: #27BDE2;
}
.sdgLogo.affordable-and-clean-energy {
  background: rgba(252, 195, 11, 0.2);
  color: #DEAD10;
}
.sdgLogo.decent-work-and-economic-growth {
  background: rgba(162, 24, 66, 0.2);
  color: #A21842;
}
.sdgLogo.industry-innovation-and-infrastructure {
  background: rgba(242, 106, 44, 0.2);
  color: #F26A2C;
}
.sdgLogo.reduced-inequalities {
  background: rgba(221, 22, 104, 0.2);
  color: #DD1668;
}
.sdgLogo.sustainable-cities-and-communities {
  background: rgba(249, 157, 40, 0.2);
  color: #F99D28;
}
.sdgLogo.responsible-consumption-and-production {
  background: rgba(191, 139, 46, 0.2);
  color: #BF8B2E;
}
.sdgLogo.climate-action {
  background: rgba(63, 126, 68, 0.2);
  color: #3F7E44;
}
.sdgLogo.life-below-water {
  background: rgba(32, 151, 212, 0.2);
  color: #2097D4;
}
.sdgLogo.life-on-land {
  background: rgba(91, 186, 71, 0.2);
  color: #5BBA47;
}
.sdgLogo.peace-justice-and-strong-institutions {
  background: rgba(6, 104, 158, 0.2);
  color: #06689E;
}
.sdgLogo.partnership-for-the-goals {
  background: rgba(24, 72, 107, 0.2);
  color: #18486B;
}
