.ItemList-topBar {
  padding-left: 18px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ItemList-topBar .ItemList-topBar-title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: #7B8989;
}
.ItemList-topBar .ItemList-itemContent .ItemList-itemContent-title {
  float: left;
  margin-right: 18px;
  width: 70px;
}
.ItemList-topBar .ItemList-itemContent .ant-btn {
  float: right;
  margin-left: 6px;
}
.ItemList-list {
  float: left;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.ItemList-item {
  float: left;
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ItemList-item:hover {
  background-color: rgba(123, 137, 137, 0.07);
}
.ItemList-item.active {
  background-color: rgba(20, 127, 116, 0.2);
}
.ItemList-item.active .ItemList-itemTitle .ItemList-itemInfo .ItemList-itemFirstTitle {
  color: #147F74;
}
.ItemList-item.active .ItemList-itemContent span {
  color: #147F74;
}
.ItemList-item.active .ItemList-itemTitle > svg g {
  stroke: #147F74;
}
.ItemList-item.active .ItemList-itemTitle > svg rect {
  fill: #147F74;
}
.ItemList-item .ItemList-itemTitle {
  float: left;
  display: flex;
  align-items: center;
  width: calc(100% - 290px);
  white-space: nowrap;
  margin-left: 18px;
}
.ItemList-item .ItemList-itemTitle > svg {
  margin-right: 8px;
}
.ItemList-item .ItemList-itemTitle > svg g {
  stroke: #273941;
}
.ItemList-item .ItemList-itemTitle > svg rect {
  fill: #273941;
}
.ItemList-item .ItemList-itemTitle .ItemList-avatar {
  float: left;
  margin-right: 12px;
}
.ItemList-item .ItemList-itemTitle .ItemList-itemInfo {
  float: left;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.ItemList-item .ItemList-itemTitle .ItemList-itemInfo .ItemList-itemFirstTitle {
  float: left;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #273941;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ItemList-item .ItemList-itemTitle .ItemList-itemInfo .ItemList-itemSecondTitle,
.ItemList-item .ItemList-itemTitle .ItemList-itemInfo .ItemList-itemThirdTitle {
  float: left;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.2px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ItemList-item .ItemList-itemContent span {
  float: left;
  margin-right: 18px;
  width: 70px;
  color: #273941;
  text-align: center;
}
.ItemList-item .ItemList-itemContent span.invited {
  color: #ff9500;
  font-weight: 500;
}
.ItemList-item .ItemList-itemContent span.active {
  color: #00b46e;
  font-weight: 500;
}
.ItemList-item .ItemList-itemContent span.blocked {
  color: #ff4545;
  font-weight: 500;
}
.ItemList-itemContent .ant-btn {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.ItemList-itemContent .ant-btn.ant-btn-primary {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  text-align: center;
}
.ItemList-itemContent .ant-btn span {
  margin: 0px;
  color: inherit;
  width: 70px;
}
.ItemList-itemContent .ant-btn svg g {
  fill: #fff;
}
.ItemList-searchox.ant-input-group-wrapper {
  width: 100%;
  margin-left: 18px;
  margin-bottom: 30px;
}
