.Collaboration-col {
  margin-bottom: 15px;
}
.collaborationTitle {
  display: inline;
  float: left;
}
.collaborationsTabs {
  float: left;
  padding-left: 36px;
  padding-top: 4px;
  margin: 10px 26px;
  border-left: 1px solid rgba(154, 154, 162, 0.3);
}
.collaborationsTabs .tab {
  float: left;
  font-size: 29px;
  padding: 0px 6px;
  padding-bottom: 1px;
  font-weight: 400;
  color: #7B8989;
  margin-right: 24px;
  cursor: pointer;
}
.collaborationsTabs .tab:hover {
  color: #273941;
}
.collaborationsTabs .tab.active {
  font-weight: 500;
  color: #273941;
  border-bottom: 2px solid #147F74;
}
