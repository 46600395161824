.IndicatorForm-field-name,
.IndicatorForm-field-value,
.IndicatorForm-field-units,
.IndicatorForm-date-picker,
.IndicatorForm-field-ask {
  margin-bottom: 0px;
}
.IndicatorForm-field-name > div,
.IndicatorForm-field-value > div {
  display: inline-block;
  width: 70%;
  text-align: right;
}
@media (min-width: 768px) {
  .IndicatorForm-field-name > div,
  .IndicatorForm-field-value > div {
    width: 70%;
  }
}
.IndicatorForm-field-name > div:first-child,
.IndicatorForm-field-value > div:first-child {
  width: 30%;
}
@media (min-width: 768px) {
  .IndicatorForm-field-name > div:first-child,
  .IndicatorForm-field-value > div:first-child {
    width: 30%;
  }
}
.IndicatorForm-field-ask {
  margin-bottom: 24px;
}
.IndicatorForm-field-ask > div {
  display: inline-block;
  width: 30%;
  text-align: right;
}
@media (min-width: 768px) {
  .IndicatorForm-field-ask > div {
    width: 30%;
  }
}
.IndicatorForm-field-ask > div:first-child {
  width: 70%;
}
@media (min-width: 768px) {
  .IndicatorForm-field-ask > div:first-child {
    width: 70%;
  }
}
.IndicatorForm-date-picker .ant-calendar-picker,
.IndicatorForm-field-value .ant-input-number {
  width: 100%;
}
.IndicatorForm-table-actions {
  text-align: center;
  padding: 16px 8px !important;
}
.IndicatorForm-table-actions-button,
.IndicatorForm-action-button {
  margin-left: 10px;
}
.IndicatorForm-table-actions-button:first-child,
.IndicatorForm-action-button:first-child {
  margin-left: 0;
}
@media (min-width: 768px) {
  .IndicatorForm-modal > div {
    width: 768px !important;
  }
}
.IndicatorForm-modal .ant-modal-content {
  border-radius: 4px;
}
.IndicatorForm-modal .ant-select-selection-placeholder {
  text-align: left;
}
.IndicatorForm-field-id {
  display: none;
}
