.indicatorMap {
  height: 600px;
  width: 100%;
  z-index: 1;
}
.indicatorMap .ant-card {
  width: 270px;
}
.leaflet-popup-content-wrapper {
  padding: 0px;
}
.leaflet-popup-close-button {
  display: none;
}
.leaflet-popup-tip-container {
  display: none;
}
