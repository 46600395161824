.progress-text {
  font-size: 15px;
  color: #273941;
}
.progress-text-top {
  float: right;
  font-size: 15px;
  color: #7b8989;
  text-align: right;
}
