.Initiative-filtersBar {
  margin-bottom: 24px;
}
.Initiative-filtersBar .button {
  height: 46px;
}
.Initiative-filtersBar .button .ant-input-group-addon .ant-btn {
  height: 46px;
}
.Initiative-filtersBar .Initiative-datePicker {
  height: 46px;
  border-radius: 6px;
}
.Initiative-filtersBar .Initiative-filtersButton {
  height: 46px;
  width: 46px;
}
.Initiative-taxonomy-filter {
  margin: 20px 5px 35px;
}
.Initiative-taxonomy-filter:first-child {
  margin-top: 5px;
}
.Initiative-taxonomy-filter:last-child {
  margin-bottom: 15px;
}
.Initiative-taxonomy-filter-sdg-logo {
  height: 20px;
  width: 20px;
  display: inline-block;
  padding-top: 0;
  margin-top: 5px;
  margin-bottom: -5px;
  margin-right: 10px;
}
.Initiative-taxonomy-filter .ant-checkbox-wrapper {
  margin-bottom: 5px;
}
.Initiative-taxonomy-filter .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 25px;
}
.Initiative-filter {
  margin-top: 5px;
}
.Initiative-filter-title {
  color: #888;
}
.initiativesTitle {
  display: inline;
  float: left;
}
.initiativesTabs {
  float: left;
  padding-left: 36px;
  padding-top: 4px;
  margin: 10px 26px 36px 26px;
  border-left: 1px solid rgba(154, 154, 162, 0.3);
}
.initiativesTabs .tab {
  float: left;
  font-size: 29px;
  padding: 0px 6px;
  padding-bottom: 1px;
  font-weight: 400;
  color: #7B8989;
  margin-right: 24px;
  cursor: pointer;
}
.initiativesTabs .tab:hover {
  color: #273941;
}
.initiativesTabs .tab.active {
  font-weight: 500;
  color: #273941;
  border-bottom: 2px solid #147F74;
}
.addInitiativeButton {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 64px;
  height: 64px;
  z-index: 2;
  background: linear-gradient(180deg, #0EAF9F 0%, #0D6A60 100%);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15), inset 0 2px 1px 0 rgba(255, 255, 255, 0.15);
  border: 0px;
}
.addInitiativeButton:hover {
  background-image: linear-gradient(180deg, #15D8C4 0%, #0E897C 100%);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3), inset 0 2px 1px 0 rgba(255, 255, 255, 0.15);
  border: 0;
}
.addInitiativeButton:focus {
  background-image: linear-gradient(180deg, #147F74 0%, #147F74 100%);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.29), inset 0 2px 1px 0 rgba(255, 255, 255, 0.15);
  border: 0;
}
.initiativeContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
  grid-column-gap: 6px;
  grid-row-gap: 24px;
  float: left;
}
.pastData .InitiativeCard {
  opacity: 0.7;
}
.initiativeDivider {
  width: 100%;
  float: left;
  margin: 50px 0;
  display: flex;
  align-items: center;
}
.initiativeDivider .line {
  float: left;
  height: 2px;
  background: rgba(123, 137, 137, 0.2);
  width: calc((100% - 186px) / 2);
  margin: 0;
}
.initiativeDivider .alreadyPassed {
  float: left;
  width: 186px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.initiativeDivider .alreadyPassed span {
  float: left;
  font-size: 17px;
  color: #7b8989;
  text-align: center;
  letter-spacing: 0.01em;
  margin-right: 6px;
}
.initiativeDivider .alreadyPassed figure {
  float: left;
  height: 17px;
  width: 26px;
  background-color: #7b8989;
  border-radius: 9px;
  font-size: 13px;
  color: #FFF;
  text-align: center;
  font-weight: bold;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
