.actionStats-topInfo .actionStats-nParticipants,
.actionStats-topInfo .actionStats-nHours,
.actionStats-topInfo .actionStats-nBeneficiaries {
  display: flex;
  align-items: center;
}
.actionStats-topInfo .actionStats-nParticipants .number,
.actionStats-topInfo .actionStats-nHours .number,
.actionStats-topInfo .actionStats-nBeneficiaries .number {
  float: left;
  font-size: 5vw;
  color: #000000;
  text-align: left;
  letter-spacing: 0.01em;
  font-weight: lighter;
  margin-right: 12px;
}
.actionStats-topInfo .actionStats-nParticipants .title,
.actionStats-topInfo .actionStats-nHours .title,
.actionStats-topInfo .actionStats-nBeneficiaries .title {
  float: left;
  font-size: 2vw;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  font-weight: lighter;
  width: 100%;
}
.actionStats-topInfo .actionStats-nParticipants .stat,
.actionStats-topInfo .actionStats-nHours .stat,
.actionStats-topInfo .actionStats-nBeneficiaries .stat {
  float: left;
  font-size: 1.5vw;
  text-align: left;
  letter-spacing: 0.01em;
  font-weight: bold;
  margin-top: -0.5vw;
}
.actionStats-topInfo .actionStats-nParticipants .stat {
  color: #273941;
}
.actionStats-topInfo .actionStats-nParticipants .stat.gold {
  color: #ffd15c;
}
.actionStats-topInfo .actionStats-nParticipants img {
  margin-top: -8px;
  margin-left: 11px;
}
.actionStats-topInfo .actionStats-nHours .stat {
  color: #273941;
}
.actionStats-topInfo .actionStats-nHours .stat.gold {
  color: #ffd15c;
}
.actionStats-topInfo .actionStats-nHours .stat.green {
  color: #3ccc75;
}
.actionStats-topInfo .actionStats-nHours .stat.red {
  color: #ff6060;
}
.actionStats-topInfo .actionStats-nHours img {
  margin-top: -8px;
  margin-left: 11px;
}
.actionStats-topInfo .actionStats-ranking {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.actionStats-topInfo .actionStats-ranking .title {
  float: left;
  font-size: 1.5vw;
  color: #7b8989;
  text-align: right;
  letter-spacing: 0.01em;
  font-weight: lighter;
  margin-right: 12px;
}
.actionStats-topInfo .actionStats-ranking .number {
  float: left;
  font-size: 5vw;
  color: #000000;
  text-align: left;
  letter-spacing: 0.01em;
  font-weight: lighter;
}
.actionStats-card {
  float: left;
  width: 100%;
  height: 400px;
  background-color: #FFF;
  border: 1px solid rgba(123, 137, 137, 0.1);
  border-radius: 6px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  padding-left: 18px;
  margin-bottom: 20px;
}
.actionStats-card.indicators-card {
  padding: 0px;
}
.actionStats-card.indicators-card .title {
  padding-left: 18px;
  width: 100%;
}
.actionStats-card.indicators-card .content .ant-col {
  overflow-y: scroll;
  height: 100%;
}
.actionStats-card.indicators-card .content .ant-col:first-child {
  border-right: 2px solid #e5e7e7;
  height: 100%;
}
.actionStats-card.indicators-card .content .ant-col:first-child .ant-list-item {
  padding: 10px 9px 10px 18px;
}
.actionStats-card.indicators-card .content .ant-col:first-child .ant-list-item:hover,
.actionStats-card.indicators-card .content .ant-col:first-child .ant-list-item.active {
  cursor: pointer;
  background: #f6f7f7;
}
.actionStats-card.indicators-card .content .ant-col:last-child .ant-list-item {
  padding-left: 9px;
  padding-right: 18px;
}
.actionStats-card .title {
  float: left;
  width: calc(100% - 18px);
  font-size: 19px;
  color: #273941;
  text-align: left;
  height: 56px;
  border-bottom: 1px solid rgba(123, 137, 137, 0.2);
  display: flex;
  align-items: center;
  margin-right: 18px;
}
.actionStats-card .content {
  float: left;
  width: 100%;
  height: calc(100% - 56px);
}
.actionStats-card .leftSide,
.actionStats-card .rightSide {
  height: 100%;
  float: left;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.actionStats-card .leftSide {
  display: flex;
}
.actionStats-card .leftSide .ant-progress-circle-path:nth-child(3) {
  stroke: #3ccc75 !important;
}
.actionStats-card .leftSide .porcentageText {
  width: 100%;
  float: left;
  font-size: 51px;
  color: #273941;
  font-weight: bold;
}
.actionStats-card .leftSide .attendanceText {
  font-size: 11px;
  float: left;
  width: 100%;
  font-size: 21px;
  color: #7b8989;
}
.actionStats-card .leftSide .progressData {
  float: left;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
}
.actionStats-card .leftSide .progressData .attended,
.actionStats-card .leftSide .progressData .noHours,
.actionStats-card .leftSide .progressData .blocked,
.actionStats-card .leftSide .progressData .notGoing {
  float: left;
  width: 33%;
}
.actionStats-card .leftSide .progressData .attended .number,
.actionStats-card .leftSide .progressData .noHours .number,
.actionStats-card .leftSide .progressData .blocked .number,
.actionStats-card .leftSide .progressData .notGoing .number {
  float: left;
  width: 100%;
  font-size: 51px;
  text-align: center;
  font-weight: lighter;
}
.actionStats-card .leftSide .progressData .attended .number-description,
.actionStats-card .leftSide .progressData .noHours .number-description,
.actionStats-card .leftSide .progressData .blocked .number-description,
.actionStats-card .leftSide .progressData .notGoing .number-description {
  float: left;
  width: 100%;
  font-size: 15px;
  color: #7b8989;
  text-align: center;
}
.actionStats-card .leftSide .progressData .attended .number {
  color: #3ccc75;
}
.actionStats-card .leftSide .progressData .noHours .number {
  color: #7b8989;
}
.actionStats-card .leftSide .progressData .blocked .number {
  color: #ff6060;
}
.actionStats-card .leftSide .progressData .notGoing .number {
  color: #7b8989;
}
.actionStats-card .leftSide .progressData .separator {
  float: left;
  width: 1px;
  height: 61px;
  background: rgba(123, 137, 137, 0.3);
  margin: 0px;
}
.actionStats-card .rightSide.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
.actionStats-card .rightSide {
  border-left: 1px solid rgba(123, 137, 137, 0.2);
  justify-content: flex-start;
  position: relative;
  overflow-y: scroll;
}
.actionStats-card .rightSide .participant:nth-child(even) {
  background: rgba(123, 137, 137, 0.07);
}
.actionStats-card .rightSide .participant {
  height: 52px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  padding: 0 5px;
}
.actionStats-card .rightSide .participant .avatar {
  width: 40px;
  height: 40px;
  margin: 6px 0;
  border-radius: 50%;
  background-color: #147F74;
}
.actionStats-card .rightSide .participant .avatar-deleted {
  width: 40px;
  height: 40px;
  margin: 6px 0;
}
.actionStats-card .rightSide .participant .avatar-deleted .anticon {
  margin-top: 10px;
}
.actionStats-card .rightSide .participant .name {
  font-size: 17px;
  color: #273941;
  text-align: left;
  letter-spacing: -0.01em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
.actionStats-card .rightSide .participant .state {
  font-size: 17px;
  letter-spacing: -0.01em;
}
.actionStats-card .rightSide .participant .state.accepted {
  color: #00b46e;
}
.actionStats-card .rightSide .participant .state.accepted.with0hours {
  color: #7b8989;
}
.actionStats-card .rightSide .participant .state.rejected {
  color: #ff6060;
}
.actionStats-card .rightSide .participant .state.not_going {
  color: #7b8989;
}
.actionStats-hoursContributed {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  display: block;
}
.actionStats-hoursContributed .hoursByPerson,
.actionStats-hoursContributed .hoursByGroup {
  float: left;
  width: 100%;
  height: 500px;
  background-color: #FFF;
  border: 1px solid rgba(123, 137, 137, 0.1);
  border-radius: 6px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}
.actionStats-hoursContributed .hoursByPerson .title,
.actionStats-hoursContributed .hoursByGroup .title {
  float: left;
  width: calc(100% - 36px);
  font-size: 19px;
  color: #273941;
  text-align: left;
  height: 56px;
  border-bottom: 1px solid rgba(123, 137, 137, 0.2);
  display: flex;
  align-items: center;
  margin: 0 18px;
}
.actionStats-hoursContributed .hoursByPerson .participants.empty,
.actionStats-hoursContributed .hoursByGroup .participants.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
.actionStats-hoursContributed .hoursByPerson .participants .participantInfo,
.actionStats-hoursContributed .hoursByGroup .participants .participantInfo {
  width: calc(100% - 219px);
}
.actionStats-hoursContributed .hoursByPerson .participants .participantInfo .name,
.actionStats-hoursContributed .hoursByGroup .participants .participantInfo .name {
  width: calc(100% - 34px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.actionStats-hoursContributed .hoursByPerson .groups .groupInfo,
.actionStats-hoursContributed .hoursByGroup .groups .groupInfo {
  width: calc(100% - 128px);
}
.actionStats-hoursContributed .hoursByPerson .groups .groupInfo .name,
.actionStats-hoursContributed .hoursByGroup .groups .groupInfo .name {
  width: calc(100% - 34px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.actionStats-hoursContributed .hoursByPerson .participants,
.actionStats-hoursContributed .hoursByGroup .participants,
.actionStats-hoursContributed .hoursByPerson .groups,
.actionStats-hoursContributed .hoursByGroup .groups {
  float: left;
  width: 100%;
  height: calc(100% - 56px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  overflow-y: scroll;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant:nth-child(even),
.actionStats-hoursContributed .hoursByGroup .participants .participant:nth-child(even),
.actionStats-hoursContributed .hoursByPerson .groups .participant:nth-child(even),
.actionStats-hoursContributed .hoursByGroup .groups .participant:nth-child(even),
.actionStats-hoursContributed .hoursByPerson .participants .group:nth-child(even),
.actionStats-hoursContributed .hoursByGroup .participants .group:nth-child(even),
.actionStats-hoursContributed .hoursByPerson .groups .group:nth-child(even),
.actionStats-hoursContributed .hoursByGroup .groups .group:nth-child(even) {
  background: rgba(123, 137, 137, 0.07);
}
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .topNumber,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .topNumber,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .topNumber,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .topNumber,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .topNumber,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .topNumber,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .topNumber,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .topNumber {
  font-size: 17px;
  color: #000000;
  text-align: right;
  letter-spacing: -0.01em;
  font-weight: 500;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .avatar,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .avatar,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .avatar,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .avatar,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .avatar,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .avatar,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .avatar,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .avatar {
  width: 80px;
  height: 80px;
  margin: 10px 0 10px 13px;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .avatar-deleted,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .avatar-deleted,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .avatar-deleted,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .avatar-deleted,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .avatar-deleted,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .avatar-deleted,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .avatar-deleted,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .avatar-deleted {
  width: 80px;
  height: 80px;
  margin: 10px 0 10px 13px;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .avatar-deleted .anticon,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .avatar-deleted .anticon,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .avatar-deleted .anticon,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .avatar-deleted .anticon,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .avatar-deleted .anticon,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .avatar-deleted .anticon,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .avatar-deleted .anticon,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .avatar-deleted .anticon {
  margin-top: 30px;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .participantInfo,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .participantInfo,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .participantInfo,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .participantInfo,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .participantInfo,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .participantInfo,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .participantInfo,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .participantInfo,
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .groupInfo,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .groupInfo,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .groupInfo,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .groupInfo,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .groupInfo,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .groupInfo,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .groupInfo,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .groupInfo {
  float: left;
  margin-left: 17px;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .participantInfo > section,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .participantInfo > section,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .participantInfo > section,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .participantInfo > section,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .participantInfo > section,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .participantInfo > section,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .participantInfo > section,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .participantInfo > section,
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .groupInfo > section,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .groupInfo > section,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .groupInfo > section,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .groupInfo > section,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .groupInfo > section,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .groupInfo > section,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .groupInfo > section,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .groupInfo > section {
  float: left;
  width: 100%;
  display: flex;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .participantInfo img,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .participantInfo img,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .participantInfo img,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .participantInfo img,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .participantInfo img,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .participantInfo img,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .participantInfo img,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .participantInfo img,
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .groupInfo img,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .groupInfo img,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .groupInfo img,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .groupInfo img,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .groupInfo img,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .groupInfo img,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .groupInfo img,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .groupInfo img {
  float: left;
  margin-right: 6px;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .participantInfo .name,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .participantInfo .name,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .participantInfo .name,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .participantInfo .name,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .participantInfo .name,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .participantInfo .name,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .participantInfo .name,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .participantInfo .name,
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .groupInfo .name,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .groupInfo .name,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .groupInfo .name,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .groupInfo .name,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .groupInfo .name,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .groupInfo .name,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .groupInfo .name,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .groupInfo .name {
  font-size: 25px;
  color: #000000;
  text-align: left;
  letter-spacing: -0.01em;
  font-weight: bold;
  margin-left: 0px;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .participantInfo .groupName,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .participantInfo .groupName,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .participantInfo .groupName,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .participantInfo .groupName,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .participantInfo .groupName,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .participantInfo .groupName,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .participantInfo .groupName,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .participantInfo .groupName,
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .groupInfo .groupName,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .groupInfo .groupName,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .groupInfo .groupName,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .groupInfo .groupName,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .groupInfo .groupName,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .groupInfo .groupName,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .groupInfo .groupName,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .groupInfo .groupName {
  float: left;
  width: 100%;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant:first-child .hours,
.actionStats-hoursContributed .hoursByGroup .participants .participant:first-child .hours,
.actionStats-hoursContributed .hoursByPerson .groups .participant:first-child .hours,
.actionStats-hoursContributed .hoursByGroup .groups .participant:first-child .hours,
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child .hours,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child .hours,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child .hours,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child .hours {
  font-size: 25px;
  color: #000000;
  text-align: right;
  letter-spacing: -0.01em;
  font-weight: bold;
}
.actionStats-hoursContributed .hoursByPerson .participants .group:first-child,
.actionStats-hoursContributed .hoursByGroup .participants .group:first-child,
.actionStats-hoursContributed .hoursByPerson .groups .group:first-child,
.actionStats-hoursContributed .hoursByGroup .groups .group:first-child {
  min-height: 100px;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant,
.actionStats-hoursContributed .hoursByGroup .participants .participant,
.actionStats-hoursContributed .hoursByPerson .groups .participant,
.actionStats-hoursContributed .hoursByGroup .groups .participant,
.actionStats-hoursContributed .hoursByPerson .participants .group,
.actionStats-hoursContributed .hoursByGroup .participants .group,
.actionStats-hoursContributed .hoursByPerson .groups .group,
.actionStats-hoursContributed .hoursByGroup .groups .group {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant .topNumber,
.actionStats-hoursContributed .hoursByGroup .participants .participant .topNumber,
.actionStats-hoursContributed .hoursByPerson .groups .participant .topNumber,
.actionStats-hoursContributed .hoursByGroup .groups .participant .topNumber,
.actionStats-hoursContributed .hoursByPerson .participants .group .topNumber,
.actionStats-hoursContributed .hoursByGroup .participants .group .topNumber,
.actionStats-hoursContributed .hoursByPerson .groups .group .topNumber,
.actionStats-hoursContributed .hoursByGroup .groups .group .topNumber {
  float: left;
  font-size: 13px;
  color: #7b8989;
  text-align: left;
  letter-spacing: -0.01em;
  margin-left: 18px;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant .avatar,
.actionStats-hoursContributed .hoursByGroup .participants .participant .avatar,
.actionStats-hoursContributed .hoursByPerson .groups .participant .avatar,
.actionStats-hoursContributed .hoursByGroup .groups .participant .avatar,
.actionStats-hoursContributed .hoursByPerson .participants .group .avatar,
.actionStats-hoursContributed .hoursByGroup .participants .group .avatar,
.actionStats-hoursContributed .hoursByPerson .groups .group .avatar,
.actionStats-hoursContributed .hoursByGroup .groups .group .avatar {
  float: left;
  width: 40px;
  height: 40px;
  margin: 6px 0 6px 13px;
  border-radius: 50%;
  background-color: #147F74;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant .avatar-deleted,
.actionStats-hoursContributed .hoursByGroup .participants .participant .avatar-deleted,
.actionStats-hoursContributed .hoursByPerson .groups .participant .avatar-deleted,
.actionStats-hoursContributed .hoursByGroup .groups .participant .avatar-deleted,
.actionStats-hoursContributed .hoursByPerson .participants .group .avatar-deleted,
.actionStats-hoursContributed .hoursByGroup .participants .group .avatar-deleted,
.actionStats-hoursContributed .hoursByPerson .groups .group .avatar-deleted,
.actionStats-hoursContributed .hoursByGroup .groups .group .avatar-deleted {
  float: left;
  width: 40px;
  height: 40px;
  margin: 6px 0 6px 13px;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant .avatar-deleted .anticon,
.actionStats-hoursContributed .hoursByGroup .participants .participant .avatar-deleted .anticon,
.actionStats-hoursContributed .hoursByPerson .groups .participant .avatar-deleted .anticon,
.actionStats-hoursContributed .hoursByGroup .groups .participant .avatar-deleted .anticon,
.actionStats-hoursContributed .hoursByPerson .participants .group .avatar-deleted .anticon,
.actionStats-hoursContributed .hoursByGroup .participants .group .avatar-deleted .anticon,
.actionStats-hoursContributed .hoursByPerson .groups .group .avatar-deleted .anticon,
.actionStats-hoursContributed .hoursByGroup .groups .group .avatar-deleted .anticon {
  margin-top: 10px;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant .name,
.actionStats-hoursContributed .hoursByGroup .participants .participant .name,
.actionStats-hoursContributed .hoursByPerson .groups .participant .name,
.actionStats-hoursContributed .hoursByGroup .groups .participant .name,
.actionStats-hoursContributed .hoursByPerson .participants .group .name,
.actionStats-hoursContributed .hoursByGroup .participants .group .name,
.actionStats-hoursContributed .hoursByPerson .groups .group .name,
.actionStats-hoursContributed .hoursByGroup .groups .group .name {
  float: left;
  font-size: 17px;
  color: #273941;
  text-align: left;
  letter-spacing: -0.01em;
  margin-left: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
.actionStats-hoursContributed .hoursByPerson .participants .participant .hours,
.actionStats-hoursContributed .hoursByGroup .participants .participant .hours,
.actionStats-hoursContributed .hoursByPerson .groups .participant .hours,
.actionStats-hoursContributed .hoursByGroup .groups .participant .hours,
.actionStats-hoursContributed .hoursByPerson .participants .group .hours,
.actionStats-hoursContributed .hoursByGroup .participants .group .hours,
.actionStats-hoursContributed .hoursByPerson .groups .group .hours,
.actionStats-hoursContributed .hoursByGroup .groups .group .hours {
  position: absolute;
  right: 18px;
  font-size: 17px;
  color: #273941;
  text-align: right;
  letter-spacing: -0.01em;
  font-weight: 500;
}
.actionStats-hoursContributed .hoursByPerson .participants .group,
.actionStats-hoursContributed .hoursByGroup .participants .group,
.actionStats-hoursContributed .hoursByPerson .groups .group,
.actionStats-hoursContributed .hoursByGroup .groups .group {
  padding: 13.5px 0;
}
@media only screen and (min-width: 1200px) {
  .actionStats-hoursContributed {
    display: flex;
    justify-content: space-between;
  }
  .actionStats-hoursContributed .hoursByPerson,
  .actionStats-hoursContributed .hoursByGroup {
    width: calc(50% - 6px);
  }
}
