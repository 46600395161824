@media print {
  @page {
    size: 8.3in 11.7in;
    margin: 0.2in;
  }
  .ant-layout-sider,
  .ant-layout-header,
  .Filters,
  .Dashboard-switcher,
  .Reporting-header-print,
  .Dashboard-graph-download {
    display: none !important;
  }
  .ant-layout-has-sider > .ant-layout {
    max-width: 11.3in;
  }
  .Dashboard-topInfos {
    display: flex;
    justify-content: space-around;
  }
  .Dashboard-cards {
    width: 11.3in;
  }
  .Dashboard-cards .Dashboard-midGraph {
    width: 5in;
  }
  .Dashboard-cards .Dashboard-fullGraph:nth-child(3) {
    margin-bottom: 4.1in;
  }
  .Dashboard-cards .Dashboard-fullGraph {
    height: 4.5in !important;
    width: 10.8in;
  }
  .Dashboard-cards .Dashboard-fullGraph .Dashboard-fullGraph-bottom {
    height: 4.4in !important;
  }
}
