.InitiativeForm-card,
.InitiativeForm-inner-card:not(:last-child) {
  margin-bottom: 15px;
}
.InitiativeForm-card .ant-input-number {
  width: 135px;
}
.InitiativeForm-card .ant-form-item {
  margin-bottom: 15px;
}
.InitiativeForm-card .ant-form-item .donation__brAccountWrapper {
  display: flex;
  align-items: center;
}
.InitiativeForm-card .ant-form-item .donation__brAccountWrapper hr {
  width: 10px;
  border: 0.5px solid black;
  margin: 0 4px;
}
.InitiativeForm-card .ant-form-item-label {
  line-height: 39.9999px;
  text-align: right;
}
.InitiativeForm-card .ant-form-item-label-left {
  text-align: left;
}
.InitiativeForm-card .ant-form-item-control {
  display: block;
  width: 100%;
  flex: none;
}
.InitiativeForm-card .ant-form-item-explain {
  min-height: 12px;
}
.InitiativeForm-card .InitiativeFormDates .ant-form-item-label {
  text-align: left;
}
@media screen and (max-width: 600px) {
  .InitiativeForm-card .InitiativeFormDates__registrationEnd .ant-form-item-label,
  .InitiativeForm-card .InitiativeFormDates__workingHours .ant-form-item-label {
    line-height: 20px;
  }
  .InitiativeForm-card .InitiativeFormDates__registrationEnd .ant-form-item-label > label,
  .InitiativeForm-card .InitiativeFormDates__workingHours .ant-form-item-label > label {
    height: 55px;
  }
}
.InitiativeForm-image {
  margin: 10px 15px;
}
.InitiativeForm-image img {
  width: 100%;
}
.InitiativeForm-fileuploadwrap {
  margin: 5px 15px;
  position: relative;
  flex: 0 1;
  text-align: center;
  width: auto;
}
.InitiativeForm-fileupload {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 9;
}
.InitiativeForm-method {
  text-align: center;
  height: 280px;
}
.InitiativeForm-method .InitiativeForm-methodicon {
  margin-bottom: 15px;
}
.InitiativeForm-required-star {
  font-size: 14px;
  color: #D65050;
}
.InitiativeForm-clarification {
  text-align: left;
  margin-bottom: 30px;
}
.InitiativeForm-sdg .ant-list-item-meta-avatar {
  width: 40px;
  height: 40px;
}
.InitiativeForm-sdg .ant-list-item-content {
  justify-content: flex-start;
}
.InitiativeForm-sdg .ant-list-item-meta {
  flex: 0 1;
}
.InitiativeForm-sdg {
  justify-content: flex-start;
}
.InitiativeForm-sdglogo-wrapper {
  position: relative;
}
.InitiativeForm-sdglogo {
  margin-bottom: 30px;
}
.InitiativeForm-sdglogo-checkbox {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.ant-card-head {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.InitiativeForm-method:hover {
  background: #E5E8E8;
}
.InitiativeForm-method.active {
  box-shadow: 0 0 0 2px #147F74;
  border-color: #147F74;
  background: transparent;
}
.InitiativeForm-method.active .ant-card-head {
  background: #147F74;
  color: #FFF;
  font-weight: bold;
}
.InitiativeForm-method.active .InitiativeForm-methodicon {
  color: #147F74;
}
.InitiativeForm__icon {
  font-size: 40px;
  height: 40px;
  width: 40px;
  text-align: left;
  margin-right: 10px;
  color: #147F74;
}
.InitiativeForm-checkbox {
  margin-bottom: 15px;
}
.InitiativeForm-checkbox:first-child {
  margin-top: 6px;
}
.InitiativeForm-sources input[type=checkbox] {
  width: 100%;
  height: 100%;
}
.InitiativeForm-sources .ant-collapse-header.ant-collapse-header.ant-collapse-header {
  padding-left: 50px;
}
.InitiativeForm-location-toggle {
  margin-top: 10px;
}
.InitiativeForm__proposalHeader {
  margin-top: 8px;
}
.InitiativeForm__proposerHelp {
  margin-left: 5px;
}
.InitiativeForm__categories > span,
.InitiativeForm__targetAudience > span {
  margin-left: 3px;
}
