.Stats-header {
  display: flex;
  align-items: center;
}
.Stats-header .Stats-title {
  display: inline;
}
.Stats-header .Stats-print {
  margin-left: 5px;
  height: 36px;
}
.Dashboard-timepicker {
  float: left;
  width: 100%;
  height: 50px;
}
.Dashboard-topInfo {
  float: left;
  width: 100%;
  padding-bottom: 48px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(123, 137, 137, 0.2);
  margin-top: 38px;
  display: flex;
  align-items: center;
}
.Dashboard-cards {
  float: left;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 12px;
  grid-row-gap: 30px;
}
.Dashboard-number {
  border-right: 1px solid rgba(123, 137, 137, 0.2);
  position: relative;
  padding: 0 1vw;
}
.Dashboard-number .Dasboard-number-top {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Dashboard-number .Dasboard-number-top .Dashboard-number-left {
  margin-right: 12px;
}
.Dashboard-number .Dasboard-number-top .Dashboard-number-left div {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}
.Dashboard-number .Dasboard-number-top .Dashboard-number-left output {
  font-size: 5vw;
  font-weight: 300;
  letter-spacing: 0.78px;
  color: #000;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}
.Dashboard-number .Dasboard-number-top .Dashboard-number-right {
  display: flex;
  flex-direction: column;
}
.Dashboard-number .Dasboard-number-top .Dashboard-number-right .Dashboard-number-title {
  width: 100%;
  font-size: 2vw;
  font-weight: 300;
  letter-spacing: 0.3px;
  color: #7b8989;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}
.Dashboard-number .Dasboard-number-top .Dashboard-number-right .Dashboard-number-subtitle {
  width: 100%;
  font-size: 1.2vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-top: 3px;
}
.Dashboard-number .Dasboard-number-top .Dashboard-number-right .Dashboard-number-subtitle.green {
  color: #00b46e;
}
.Dashboard-number .Dasboard-number-top .Dashboard-number-right .Dashboard-number-subtitle.red {
  color: #ff4545;
}
.Dashboard-number .Dasboard-number-bottom {
  display: flex;
}
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-collection,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-training,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-volunteering,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-donation,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-internal,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-informative,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-org {
  margin-right: 1.5vw;
  display: flex;
  align-items: center;
}
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-collection svg,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-training svg,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-volunteering svg,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-donation svg,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-internal svg,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-informative svg,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-org svg,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-collection .org-avatar,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-training .org-avatar,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-volunteering .org-avatar,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-donation .org-avatar,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-internal .org-avatar,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-informative .org-avatar,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-org .org-avatar {
  margin-right: 8px;
  width: 2vw;
  height: 2vw;
}
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-collection span,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-training span,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-volunteering span,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-donation span,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-internal span,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-informative span,
.Dashboard-number .Dasboard-number-bottom .Dasboard-number-org span {
  font-size: 1.4vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.18px;
  color: #273941;
}
.Dashboard-number:first-child {
  padding-left: 0px;
}
.Dashboard-number:last-child {
  border: none;
}
.Dashboard-contribution {
  height: 95px;
  padding: 0 2vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.Dashboard-contribution .Dasboard-contribution-top {
  width: 100%;
}
.Dashboard-contribution .Dasboard-contribution-top div {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}
.Dashboard-contribution .Dasboard-contribution-top output,
.Dashboard-contribution .Dasboard-contribution-top span {
  width: 100%;
  font-size: 2vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: #000;
}
.Dashboard-contribution .Dasboard-contribution-bottom {
  width: 100%;
}
.Dashboard-contribution .Dasboard-contribution-bottom .Dasboard-contribution-text {
  width: 100%;
  font-size: 21px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #7b8989;
}
.Dashboard-midGraph {
  float: left;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(123, 137, 137, 0.1);
  background-color: #ffffff;
  grid-column: span 1;
}
@media only screen and (max-width: 992px) {
  .Dashboard-midGraph.responsive {
    grid-column: span 2;
  }
}
.Dashboard-midGraph .Dashboard-midGraph-top {
  float: left;
  width: calc(100% - 36px);
  margin: 18px 18px 0 18px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(123, 137, 137, 0.2);
}
.Dashboard-midGraph .Dashboard-midGraph-top .Dashboard-midGraph-title {
  float: left;
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.09px;
  color: #273941;
}
.Dashboard-midGraph .Dashboard-midGraph-bottom {
  float: left;
  width: 100%;
  height: calc(100% - 57px);
  display: flex;
}
.Dashboard-fullGraph {
  float: left;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(123, 137, 137, 0.1);
  background-color: #ffffff;
  grid-column: span 2;
  overflow: hidden;
  padding-bottom: 55px;
  height: 100%;
  max-height: 100vh;
}
.Dashboard-fullGraph .Dashboard-fullGraph-top {
  float: left;
  width: calc(100% - 36px);
  margin: 18px 18px 0 18px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(123, 137, 137, 0.2);
}
.Dashboard-fullGraph .Dashboard-fullGraph-top .Dashboard-fullGraph-title {
  float: left;
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.09px;
  color: #273941;
}
.Dashboard-fullGraph .Dashboard-fullGraph-top .Dashboard-switcher {
  margin-right: 20px;
}
.Dashboard-fullGraph .Dashboard-fullGraph-top .Dashboard-switcher .Switch-competences,
.Dashboard-fullGraph .Dashboard-fullGraph-top .Dashboard-switcher .Switch-categories {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  text-align: center;
  color: #7b8989;
  float: left;
  padding: 4px 25px;
  cursor: pointer;
}
.Dashboard-fullGraph .Dashboard-fullGraph-top .Dashboard-switcher .Switch-competences {
  border-left: 2px solid rgba(123, 137, 137, 0.2);
  border-top: 2px solid rgba(123, 137, 137, 0.2);
  border-bottom: 2px solid rgba(123, 137, 137, 0.2);
  border-radius: 6px 0 0 6px;
}
.Dashboard-fullGraph .Dashboard-fullGraph-top .Dashboard-switcher .Switch-categories {
  border-right: 2px solid rgba(123, 137, 137, 0.2);
  border-top: 2px solid rgba(123, 137, 137, 0.2);
  border-bottom: 2px solid rgba(123, 137, 137, 0.2);
  border-radius: 0 6px 6px 0;
}
.Dashboard-fullGraph.competences .Switch-competences,
.Dashboard-fullGraph.categories .Switch-categories {
  border-color: #147F74;
  background-color: #147F74;
  font-weight: 500 !important;
  color: #FFF !important;
}
.Dashboard-fullGraph .Dashboard-fullGraph-bottom {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
}
.Dashboard-fullGraph .Dashboard-fullGraph-bottom .Dashboard-leftSide {
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
  border-right: 1px solid rgba(123, 137, 137, 0.2);
  overflow-y: scroll;
  overflow-x: hidden;
}
.Dashboard-fullGraph .Dashboard-fullGraph-bottom .Dashboard-leftSide .emptyTable {
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Dashboard-fullGraph .Dashboard-fullGraph-bottom .Dashboard-leftSide .Dashboard-table-action {
  float: left;
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  position: relative;
}
.Dashboard-fullGraph .Dashboard-fullGraph-bottom .Dashboard-leftSide .Dashboard-table-action .Dashboard-table-action-ranking {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.15px;
  color: #7b8989;
  margin-right: 13px;
  margin-left: 18px;
}
.Dashboard-fullGraph .Dashboard-fullGraph-bottom .Dashboard-leftSide .Dashboard-table-action .Dashboard-table-action-sdg {
  margin-right: 19px;
}
.Dashboard-fullGraph .Dashboard-fullGraph-bottom .Dashboard-leftSide .Dashboard-table-action .Dashboard-table-action-sdg .sdgLogo {
  margin: 0px 2px 0px 0px;
}
.Dashboard-fullGraph .Dashboard-fullGraph-bottom .Dashboard-leftSide .Dashboard-table-action .Dashboard-table-action-name {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #273941;
  position: absolute;
  left: 100px;
  width: calc(100% - 180px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Dashboard-fullGraph .Dashboard-fullGraph-bottom .Dashboard-leftSide .Dashboard-table-action .Dashboard-table-action-hours {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: right;
  color: #273941;
  position: absolute;
  right: 24px;
}
.Dashboard-fullGraph .Dashboard-fullGraph-bottom .Dashboard-leftSide .Dashboard-table-action:nth-child(even) {
  background-color: rgba(123, 137, 137, 0.07);
}
.Dashboard-fullGraph .Dashboard-fullGraph-bottom .Dashboard-leftSide .Dashboard-table-action.one .Dashboard-table-action-ranking {
  margin-right: 24px;
}
.Dashboard-fullGraph .Dashboard-fullGraph-bottom .Dashboard-leftSide .Dashboard-table-action.three .Dashboard-table-action-sdg {
  position: relative;
  margin-top: -17px;
}
.Dashboard-fullGraph .Dashboard-fullGraph-bottom .Dashboard-leftSide .Dashboard-table-action.three .Dashboard-table-action-sdg .sdgLogo:nth-child(3) {
  position: absolute;
  top: 18px;
  left: 10px;
}
.Dashboard-fullGraph .Dashboard-fullGraph-bottom .Dashboard-rightSide {
  float: right;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}
.DashboardComponent__container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  place-items: stretch;
  place-content: stretch;
  grid-auto-flow: row;
  /* row dense */
  flex-wrap: wrap;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
}
@media (min-width: 576px) {
  .DashboardComponent__container {
    display: block;
  }
}
@media (min-width: 768px) {
  .DashboardComponent__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .DashboardComponent__container {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1200px) {
  .DashboardComponent__container {
    grid-template-columns: repeat(6, 1fr);
  }
}
.DashboardComponent__card {
  margin: 0;
  padding: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(123, 137, 137, 0.1);
  background: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  grid-column: span 1;
  grid-row: span 1;
  display: flex;
  flex-direction: column;
}
.DashboardComponent__card_content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  flex-direction: column;
  padding: 2em;
  margin: auto;
  position: relative;
  height: 100%;
}
