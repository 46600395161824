.ManagerApproval {
  padding: 36px 60px;
  background-size: cover;
  height: calc(100vh - 72px);
}
.ManagerApproval__org-logo {
  margin-bottom: 30px;
  object-fit: contain;
  display: block;
  max-width: 200px;
  max-height: 80px;
}
.ManagerApproval__title {
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7B8989;
}
.ManagerApproval__title > small {
  display: inline-block;
  margin-left: 25px;
  font-size: 21px;
  color: #7B8989;
}
.ManagerApproval__body {
  margin: 35px 0;
}
.ManagerApproval__entry_header {
  margin-bottom: 24px;
}
.ManagerApproval__entry_title {
  margin-bottom: 0;
}
.ManagerApproval__entry_table {
  margin: 10px 35px;
}
.ManagerApproval__entry_initiative_title {
  font-size: large;
}
.ManagerApproval__entry_initiative_contact_icon {
  margin-left: 5px;
}
.ManagerApproval__current {
  background: #E5E8E8;
  font-weight: bold;
}
.ManagerApproval__status__approved {
  color: #147F74;
}
.ManagerApproval__status__rejected {
  color: #D65050;
}
