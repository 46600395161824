.ColumnMenu {
  float: left;
  width: 100%;
  min-height: 700px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(123, 137, 137, 0.1);
  background-color: #ffffff;
  position: relative;
}
.ColumnMenu .ant-checkbox {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.ColumnMenu .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner::after {
  transform: scale(0.7) rotate(45deg) translate(-50%, -50%);
  margin-top: -2px;
  margin-left: -1px;
}
.ColumnMenu .ant-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
  border-radius: 3px;
}
.ColumnMenu .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 8px;
  height: 8px;
}
.ColumnMenu .ColumnMenu-leftSide {
  position: absolute;
  width: calc(50% - 1px);
  height: 100%;
  border-right: 1px solid rgba(123, 137, 137, 0.1);
}
.ColumnMenu .ColumnMenu-leftSide .ItemList-topBar {
  position: absolute;
  top: -25px;
}
.ColumnMenu .ColumnMenu-rightSide {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 50%;
  padding-top: 36px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-contentTabs {
  margin-left: 36px;
  margin-right: 36px;
  float: left;
  width: calc(100% - 72px);
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(123, 137, 137, 0.2);
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-contentTabs .ColumnMenu-tab {
  float: left;
  margin-right: 27px;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  cursor: pointer;
  color: #7B8989;
  padding-bottom: 12px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-contentTabs .ColumnMenu-tab.active {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  color: #273941;
  border-bottom: 2px solid #147F74;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-itemSelected {
  float: left;
  width: calc(100% - 72px);
  margin-left: 36px;
  margin-right: 36px;
  display: flex;
  justify-content: space-between;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-itemSelected .ColumnMenu-itemSelected-info {
  margin-bottom: 20px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-itemSelected .ColumnMenu-itemSelected-lockedTitle {
  display: flex;
  align-items: center;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-itemSelected .ColumnMenu-itemSelected-lockedTitle svg {
  margin-right: 9px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-itemSelected .ColumnMenu-itemSelected-title {
  font-size: 31px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: normal;
  color: #273941;
  float: left;
  margin-bottom: 0px;
  width: 100%;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-itemSelected .ColumnMenu-itemSelected-title .ant-typography-edit {
  font-size: 18px;
  color: #273941;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-itemSelected .ColumnMenu-itemSelected-title .anticon-enter {
  font-size: 18px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-itemSelected .ColumnMenu-itemSelected-subTitle {
  margin: 0px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-itemSelected .ColumnMenu-itemSelected-subTitle .ant-typography-edit {
  font-size: 14px;
  color: #273941;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-itemSelected .ColumnMenu-itemSelected-subTitle .anticon-enter {
  font-size: 14px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-itemSelected .ant-btn {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #7B8989;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-itemSelected .ColumnMenu-itemSelected-closedButton {
  display: flex;
  align-items: center;
  background-color: #273941;
  border-color: #273941;
  color: #FFF;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  height: 36px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-itemSelected .ColumnMenu-itemSelected-closedButton svg {
  margin-right: 8px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-subItemsList {
  overflow-y: scroll;
  float: left;
  height: calc(100% - 108px);
  width: 100%;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-subItemsList .ColumnMenu-subItemsList-tab {
  float: left;
  width: 100%;
  height: calc(100% - 18px);
  position: relative;
  display: none;
  margin-top: 18px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-subItemsList .ColumnMenu-subItemsList-tab.active {
  display: block;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-subItemsList .ColumnMenu-subItemsList-tab .ItemList-searchox.ant-input-group-wrapper {
  margin-left: 0px;
  padding: 0 36px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-subItemsList .ColumnMenu-subItemsList-tab .ItemList-topBar {
  margin: 0 0 15px 0;
  padding: 0 36px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-subItemsList .ColumnMenu-subItemsList-tab .ItemList-list {
  height: calc(100% - 106px);
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-subItemsList .ColumnMenu-subItemsList-tab .ItemList-item {
  padding: 0 36px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-subItemsList .ColumnMenu-subItemsList-tab .ItemList-item .ItemList-itemTitle {
  width: calc(100% - 120px);
  margin-left: 0px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-subItemsList .ColumnMenu-subItemsList-tab .ItemList-item .ItemList-itemTitle .ItemList-itemInfo {
  width: calc(100% - 85px);
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-subItemsList .ColumnMenu-subItemsList-tab .ItemList-item .ItemList-itemTitle .ItemList-itemInfo .ItemList-itemFirstTitle {
  margin-left: 0px;
}
.ColumnMenu .ColumnMenu-rightSide .ColumnMenu-subItemsList .ColumnMenu-subItemsList-tab .ItemList-itemContent .ant-btn {
  margin-right: 0px;
}
