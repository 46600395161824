.PhoneInput .react-phone-number-input {
  padding-right: 0;
}
.PhoneInput .react-phone-number-input__row {
  width: 100%;
}
.PhoneInput-input {
  padding-left: 0 !important;
  width: 100%;
}
.PhoneInput-select {
  width: 34px;
  margin-right: 5px;
}
.PhoneInput-select * {
  margin: 0;
  padding: 0;
}
.PhoneInput .react-phone-number-input__error {
  display: none;
}
.PhoneInput-flag {
  position: relative;
}
.react-phone-number-input__icon {
  border: 0;
}
.PhoneInput-flag .react-phone-number-input__icon img {
  position: relative;
  top: -3px;
}
.PhoneInput-flag-caret {
  position: absolute;
  bottom: 0;
  right: 0;
}
.react-phone-number-input__icon-image {
  float: left;
}
