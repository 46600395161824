.AccountsRedirect-cardwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background: transparent;
}
.AccountsRedirect-card {
  min-width: 400px;
}
.AccountsRedirect-heading {
  text-align: center;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AccountsRedirect-logo {
  margin: 10px 30px 10px 30px;
  max-width: 350px;
}
.AccountsRedirect-message {
  text-align: center;
}
@media screen and (max-width: 400px) {
  .AccountsRedirect-card {
    min-width: 100%;
  }
}
