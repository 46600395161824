.CustomTagSelector {
  position: relative;
  overflow-x: scroll;
  width: 100%;
}
.CustomTagSelector::-webkit-scrollbar {
  background: transparent;
}
.CustomTagSelector .CustomTagSelector-button {
  width: 142px;
  height: 36px;
  border-radius: 6px;
  border: solid 2px rgba(123, 137, 137, 0.2);
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  cursor: pointer;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  color: #273941;
}
.CustomTagSelector .CustomTagSelector-button.CustomTagSelector-open {
  background-color: #147F74;
  border-color: #147F74;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  color: #FFF;
}
.CustomTagSelector.CustomTagSelector-dropdown .CustomTagSelector-menu {
  width: 347px;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px rgba(123, 137, 137, 0.1);
  background-color: #FFF;
  padding: 18px 18px 18px 20px;
  position: absolute;
  z-index: 2;
  margin-top: 3px;
}
.CustomTagSelector .CustomTagSelector-menu {
  display: flex;
  flex-wrap: wrap;
}
.CustomTagSelector .CustomTagSelector-menu > span {
  margin-right: 2px;
}
.CustomTagSelector .CustomTagSelector-menu > * {
  margin-bottom: 6px;
}
.CustomTagSelector .CustomTagSelector-menu .CustomTagSelector-input {
  width: 100%;
  margin-bottom: 0px;
  box-shadow: none;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  color: #273941;
  padding-left: 5px;
  padding-right: 35px;
}
.CustomTagSelector .CustomTagSelector-menu .CustomTagSelector-input:disabled {
  background: transparent;
}
.CustomTagSelector .CustomTagSelector-menu .CustomTagSelector-input:focus {
  box-shadow: none;
  outline: none;
}
.CustomTagSelector .CustomTagSelector-menu .CustomTagSelector-add {
  border: 0;
  background: transparent;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  color: #147F74;
  text-align: right;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  outline: none;
}
