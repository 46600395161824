.IndicatorResponse__actions {
  display: flex;
  white-space: nowrap;
  justify-content: center;
}
.IndicatorResponse__modal {
  min-width: 60vw;
}
.IndicatorResponse__modal_input_number {
  margin-bottom: 15px;
  display: block;
  width: 100%;
  padding: 5px 0;
  box-sizing: content-box;
}
.IndicatorResponse__modal_input_number.ant-input-number-lg {
  font-size: 32px;
}
.IndicatorResponse__modal_input_number input {
  text-align: center;
}
.participantDetail {
  margin-right: 10px;
}
.participantDetail input {
  width: 220px;
}
