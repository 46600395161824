.Reporting-header-print {
  margin-left: 5px;
  height: 36px;
}
.Reporting-header-filters {
  height: 36px;
}
.Filters .Filters-top .Filters-divider:first-child {
  float: left;
  margin: 6px 18px 6px 18px;
  width: 1px;
  height: 24px;
  background-color: rgba(123, 137, 137, 0.3);
}
.Filters .Filters-top .Filters-divider {
  float: left;
  margin: 6px 18px 6px 18px;
  width: 1px;
  height: 24px;
  background-color: rgba(123, 137, 137, 0.3);
}
.Filters .Filters-top .Filters-datePicker {
  float: left;
  border-radius: 6px;
  border-width: 2px;
  height: 36px;
  width: auto;
  padding: 5px 22px 7px 22px;
}
.Filters .Filters-top .Filters-datePicker .ant-picker-suffix {
  right: 11px;
  position: absolute;
}
.Filters .Filters-top .Filters-drawerButton {
  margin-left: 5px;
  height: 36px;
}
.Filters .Filters-top .Filters-intervalPicker {
  float: left;
  border: solid 2px rgba(123, 137, 137, 0.2);
  border-radius: 6px;
  margin-right: 6px;
}
.Filters .Filters-top .Filters-intervalPicker span {
  float: left;
  padding: 7px 12px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  color: #273941;
  cursor: pointer;
}
.Filters .Filters-top .Filters-intervalPicker .week:hover {
  background: #e5e8e8;
  box-shadow: -2px 0px 0px 2px #e5e8e8;
}
.Filters .Filters-top .Filters-intervalPicker .month:hover {
  background: #e5e8e8;
  box-shadow: 0px 2px 0px 0px #e5e8e8, 0px -2px 0px 0px #e5e8e8;
}
.Filters .Filters-top .Filters-intervalPicker .year:hover {
  background: #e5e8e8;
  box-shadow: 0px 2px 0px 0px #e5e8e8, 0px -2px 0px 0px #e5e8e8;
}
.Filters .Filters-top .Filters-intervalPicker .allTime:hover {
  background: #e5e8e8;
  box-shadow: 2px 0px 0px 2px #e5e8e8;
}
.Filters .Filters-top .Filters-intervalPicker .week {
  border-radius: 6px 0 0 6px;
}
.Filters .Filters-top .Filters-intervalPicker .allTime {
  border-radius: 0 6px 6px 0;
}
.Filters .Filters-top .Filters-intervalPicker.week .week {
  color: #FFF;
  font-weight: bold;
  background: #147F74;
  box-shadow: -2px 0px 0px 2px #147F74;
}
.Filters .Filters-top .Filters-intervalPicker.month .month {
  color: #FFF;
  font-weight: bold;
  background: #147F74;
  box-shadow: 0px 2px 0px 0px var(--primary-color), 0px -2px 0px 0px var(--primary-color);
}
.Filters .Filters-top .Filters-intervalPicker.year .year {
  color: #FFF;
  font-weight: bold;
  background: #147F74;
  box-shadow: 0px 2px 0px 0px var(--primary-color), 0px -2px 0px 0px var(--primary-color);
}
.Filters .Filters-top .Filters-intervalPicker.allTime .allTime {
  color: #FFF;
  font-weight: bold;
  background: #147F74;
  box-shadow: 2px 0px 0px 2px #147F74;
}
.Filters .Filters-selected {
  margin-left: 40px;
}
