.organizationLogo {
  min-height: 40px;
  min-width: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  margin-right: 18px;
}
.organizationLogo img {
  object-fit: contain;
}
.initiativeTabs > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding: 0 60px;
}
@media only screen and (max-width: 1300px) {
  .initiativeTabs > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding: 0px;
  }
}
.actionTopHeader {
  width: 100%;
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
}
.actionTopHeader .backToActions {
  display: flex;
  align-items: center;
}
.actionTopHeader .backToActions img {
  margin-right: 8px;
}
.actionTopHeader .backToActions span {
  font-size: 29px;
  font-weight: 500;
  color: rgba(39, 57, 65, 0.7);
  text-align: left;
  letter-spacing: 0.01em;
  display: inline-block;
}
.actionTopHeader__featured_initiativeIcon,
.actionTopHeader__pinned_initiativeIcon,
.actionTopHeader__featured_initiativeSwitch,
.actionTopHeader__pinned_initiativeSwitch {
  align-self: center;
}
.actionTopHeader__featured_initiativeIcon {
  margin-left: 16px;
}
.actionTopHeader__featured_initiativeIcon .svg-inline--fa {
  margin-top: 6px;
  font-size: 20px;
  color: #bdbdbd;
}
.actionTopHeader__featured_initiativeIcon.checked .svg-inline--fa {
  color: #147F74;
}
.actionTopHeader__pinned_initiativeIcon > span {
  font-size: 20px;
  color: #bdbdbd;
}
.actionTopHeader__pinned_initiativeIcon.checked > span {
  color: #147F74;
}
.actionTopHeader__featured_initiativeSwitch,
.actionTopHeader__pinned_initiativeSwitch {
  margin-left: 5px;
}
.actionTopHeader__featured_initiativeSwitch .ant-switch-inner,
.actionTopHeader__pinned_initiativeSwitch .ant-switch-inner {
  font-size: 14px;
}
.actionTopHeader .editAction,
.actionTopHeader .shareAction {
  height: 48px;
  width: 186px;
  color: #273941;
  margin-left: 12px;
  font-size: 17px;
  letter-spacing: 0.01em;
  align-items: center;
  justify-content: center;
}
.actionTopHeader .editAction img {
  margin-right: 16px;
  height: 18px;
  width: 16px;
}
.actionTopHeader .shareAction img {
  margin-right: 16px;
  height: 25px;
  width: 18px;
  margin-bottom: 4px;
}
@media only screen and (max-width: 1050px) {
  .actionTopHeader .backToActions img {
    margin-right: 8px;
    width: 9px;
  }
  .actionTopHeader .backToActions span {
    font-size: 17px;
  }
  .actionTopHeader .editAction,
  .actionTopHeader .shareAction {
    width: 138px;
    margin-left: 12px;
    font-size: 15px;
  }
  .actionTopHeader .editAction img {
    margin-right: 8px;
  }
  .actionTopHeader .shareAction img {
    margin-right: 8px;
    width: 15px;
  }
}
.actionHeader {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
}
.actionHeader .actionCover {
  position: relative;
}
.actionHeader .actionCover .actionScore {
  position: absolute;
  top: 12px;
  right: 12px;
  height: 40px;
  width: 92px;
  background-color: rgba(255, 204, 0, 0.9);
  border-radius: 8px;
  font-size: 27px;
  color: #000000;
  letter-spacing: -0.01em;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.actionHeader .actionCover .actionScore img {
  height: 13px;
  width: 13px;
  margin-bottom: 3px;
  margin-left: 4px;
}
.actionHeader .actionCover img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media only screen and (max-width: 992px) {
  .actionHeader .actionCover {
    height: 20vw;
  }
}
.actionHeader .actionTopInfo {
  background: #11181b;
  padding: 30px 46px;
}
.actionHeader .actionTopInfo .date {
  font-size: 17px;
  color: rgba(255, 255, 255, 0.7);
  text-align: left;
  width: 100%;
}
.actionHeader .actionTopInfo .title {
  font-size: 3vw;
  color: #FFF;
  text-align: left;
  line-height: 3.6vw;
}
.actionHeader .sdgs {
  position: absolute;
  bottom: 30px;
  height: 40px;
}
.actionHeader .sdgs .sdg {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
.actionHeader .organizer {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 49px;
  bottom: 30px;
  background: #FFF;
  border-radius: 50%;
}
.actionHeader .organizer .avatar {
  width: 100%;
  height: 100%;
}
.actionHeader .organizer .organizerRate {
  position: absolute;
  height: 15px;
  width: 35px;
  background-color: #ffcc00;
  border-radius: 8px;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
  letter-spacing: -0.01em;
  font-weight: 500;
  bottom: -12px;
  left: -16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.actionHeader .organizer .organizerRate img {
  height: 9px;
  width: 8px;
  margin-left: 2px;
  margin-bottom: 2px;
}
.actionTabs {
  height: 70px;
  width: 100%;
  background: rgba(123, 137, 137, 0.1);
  padding-left: 88px;
  margin-bottom: 42px;
}
.actionTabs .badge {
  width: 24px;
  height: 15px;
  border-radius: 9.5px;
  background-color: #ec3550;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  text-align: center;
  color: #ffffff;
  margin: 0 0 0 6px;
}
.actionTabs .tab {
  height: 100%;
  padding: 0 18px;
  font-size: 1.8vw;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  line-height: 69px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}
.actionTabs .tab.active {
  color: #273941;
  font-weight: 500;
  border-bottom: 2px solid #117065;
}
@media only screen and (max-width: 1600px) {
  .actionTabs .tab {
    font-size: 1.4vw;
  }
}
@media only screen and (min-width: 1600px) {
  .actionTabs .tab {
    font-size: 21px;
  }
}
@media only screen and (max-width: 1050px) {
  .actionTabs {
    padding-left: 0px;
  }
  .actionTabs .tab {
    font-size: 1em;
  }
}
.actionDescription {
  width: 100%;
  padding-bottom: 22px;
  margin-bottom: 42px;
  border-bottom: 1px solid rgba(123, 137, 137, 0.2);
  white-space: pre-line;
  word-break: break-word;
}
.actionDescription .actionDescription-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionDescription .actionDescription-description,
.actionDescription .actionDescription-description-showMore {
  display: inline-block;
  overflow: hidden;
}
.actionDescription .actionDescription-description-showMore {
  max-height: 300px;
}
.actionDescription .actionDescription-showLess {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}
.actionDescription .actionDescription-showMore {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}
.actionDescription .actionDescription-showMore .actionDescription-shadow {
  position: absolute;
  top: -68px;
  width: 100%;
  height: 64px;
  background-image: linear-gradient(-180deg, rgba(252, 252, 252, 0) 0%, #fcfcfc 100%);
  margin: 0px;
}
.actionCompetences {
  width: 100%;
  margin-bottom: 42px;
}
.actionCompetences .actionCompetences-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionCompetences .actionCompetences-competence {
  width: 100%;
  font-size: 15px;
  color: #273941;
  text-align: left;
  letter-spacing: 0.01em;
  line-height: 24px;
}
.actionCategories {
  width: 100%;
  margin-bottom: 42px;
}
.actionCategories .actionCategories-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionCategories .actionCategories-category {
  width: 100%;
  font-size: 15px;
  color: #273941;
  text-align: left;
  letter-spacing: 0.01em;
  line-height: 24px;
}
.actionTargetAudience {
  width: 100%;
  margin-bottom: 42px;
}
.actionTargetAudience .actionTargetAudience-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionTargetAudience .actionTargetAudience-item {
  width: 100%;
  font-size: 15px;
  color: #273941;
  text-align: left;
  letter-spacing: 0.01em;
  line-height: 24px;
}
.actionSdgs {
  width: 100%;
  margin-bottom: 42px;
}
.actionSdgs .actionSdgs-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionSdgs .actionSdgs-sdg {
  width: 60px;
  height: 60px;
  margin-right: 12px;
  padding: 0px;
}
.actionRestrictions {
  width: 100%;
  margin-bottom: 42px;
}
.actionRestrictions .actionRestrictions-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionRestrictions .actionRestrictions-item {
  width: 100%;
  font-size: 15px;
  color: #273941;
  letter-spacing: 0.01em;
  line-height: 24px;
}
.actionGroups {
  width: 100%;
  margin-bottom: 42px;
}
.actionGroups .actionGroups-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionParticipationMethod {
  width: 100%;
  margin-bottom: 42px;
}
.actionParticipationMethod .actionParticipationMethod-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionParticipationMethod .actionParticipationMethod-qrMode,
.actionParticipationMethod .actionParticipationMethod-manualMode {
  width: 100%;
}
.actionParticipationMethod .actionParticipationMethod-qrMode > section,
.actionParticipationMethod .actionParticipationMethod-manualMode > section {
  width: 100%;
  display: flex;
  align-items: center;
}
.actionParticipationMethod .actionParticipationMethod-qr,
.actionParticipationMethod .actionParticipationMethod-manual {
  width: 80px;
  height: 80px;
  margin-right: 30px;
}
.actionParticipationMethod .actionParticipationMethod-method {
  font-size: 2.1vw;
  color: #273941;
  text-align: left;
  letter-spacing: 0.01em;
  font-weight: 500;
  margin-top: -7px;
}
.actionParticipationMethod .actionParticipationMethod-link {
  font-size: 21px;
  color: #1a7ce5;
  text-align: left;
  letter-spacing: 0.01em;
}
.actionParticipationMethod .actionParticipationMethod-instructions {
  font-size: 15px;
  color: #273941;
  text-align: left;
  letter-spacing: 0.01em;
  line-height: 22px;
  margin-top: 24px;
  width: 80%;
}
.actionWhen {
  width: 100%;
  margin-bottom: 42px;
}
.actionWhen .actionWhen-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionWhen-workinghours {
  font-weight: bold;
}
.actionWhen > span {
  width: 100%;
}
.actionProgram {
  width: 100%;
  margin-bottom: 42px;
}
.actionProgram .actionProgram-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionProgram-program {
  font-weight: bold;
}
.actionPoints,
.actionBeneficiaries {
  width: 100%;
  margin-bottom: 42px;
}
.actionPoints .actionPoints-title,
.actionBeneficiaries .actionPoints-title,
.actionPoints .actionBeneficiaries-title,
.actionBeneficiaries .actionBeneficiaries-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionPoints-points,
.actionBeneficiaries-points,
.actionPoints-actionBeneficiaries,
.actionBeneficiaries-actionBeneficiaries {
  font-size: 42px;
  font-weight: bold;
}
.actionAddress {
  width: 100%;
  margin-bottom: 42px;
}
.actionAddress .actionAddress-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionAddress .actionAddress-detail {
  color: #7b8989;
  text-align: left;
  width: 100%;
  margin-bottom: 8px;
}
.actionAddress .actionAddress-showMap {
  width: 100%;
  font-size: 15px;
  color: #1a7ce5;
  text-align: left;
  letter-spacing: 0.01em;
  line-height: 22px;
  cursor: pointer;
}
.actionContact {
  width: 100%;
  margin-bottom: 42px;
}
.actionContact .actionContact-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionContact .actionContact-item {
  width: 100%;
  font-size: 15px;
  color: #273941;
  text-align: left;
  letter-spacing: 0.01em;
  line-height: 22px;
}
.actionContact .actionContact-item.link {
  color: #1a7ce5;
}
.actionOrganizer {
  width: 100%;
  margin-bottom: 42px;
}
.actionOrganizer .actionOrganizer-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionFeedback {
  width: 100%;
  margin-bottom: 42px;
}
.actionFeedback-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionFeedback-link {
  font-size: 17px;
  color: #1a7ce5;
  text-align: left;
  letter-spacing: 0.01em;
}
.actionOrganization {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.actionOrganization .actionOrganization-avatar {
  min-height: 40px;
  min-width: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  margin-right: 18px;
}
.actionOrganization .actionOrganization-avatar img {
  object-fit: contain;
}
.actionOrganization .actionOrganization-name {
  font-size: 15px;
  color: #273941;
  text-align: left;
  letter-spacing: 0.01em;
  line-height: 22px;
}
.actionCollaborators {
  width: 100%;
  margin-bottom: 5px;
}
.actionCollaborators .actionCollaborators-collaborator {
  width: 100%;
  display: flex;
  align-items: center;
}
.actionCollaborators .actionCollaborators-avatar {
  min-height: 40px;
  min-width: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  margin-right: 18px;
}
.actionCollaborators .actionCollaborators-avatar img {
  object-fit: contain;
}
.actionCollaborators .actionCollaborators-collaborating {
  font-size: 13px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  line-height: 7px;
  margin-top: 3px;
  width: 100%;
}
.actionCollaborators .actionCollaborators-name {
  font-size: 15px;
  color: #273941;
  text-align: left;
  letter-spacing: 0.01em;
  line-height: 22px;
  width: 100%;
}
.actionSubmitter {
  width: 100%;
  margin-bottom: 5px;
}
.actionSubmitter .actionSubmitter-submitter {
  width: 100%;
  display: flex;
  align-items: center;
}
.actionSubmitter .actionSubmitter-submitter > section {
  width: calc(100% - 40px);
}
.actionSubmitter .actionSubmitter-submitter .actionSubmitter-name {
  line-height: 16px;
  margin-top: 5px;
}
.actionSubmitter .actionSubmitter-avatar {
  min-height: 40px;
  min-width: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  margin-right: 18px;
}
.actionSubmitter .actionSubmitter-avatar img {
  object-fit: contain;
}
.actionSubmitter .actionSubmitter-submittedBy {
  font-size: 13px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  line-height: 7px;
  margin-top: 3px;
  width: 100%;
}
.actionSubmitter .actionCollaborators-name {
  font-size: 15px;
  color: #273941;
  text-align: left;
  letter-spacing: 0.01em;
  line-height: 22px;
  width: 100%;
}
.actionDonation {
  width: 100%;
  margin-bottom: 42px;
}
.actionDonation .actionDonation-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionDonation .donationProgressBar .ant-progress-inner {
  height: 8px;
}
.actionDonation .donationProgressBar .ant-progress-inner .ant-progress-bg {
  height: 8px !important;
}
.actionDonation .actionDonation-stats {
  width: 100%;
}
.actionDonation .actionDonation-stats .actionDonation-contributed {
  width: 40%;
}
.actionDonation .actionDonation-stats .actionDonation-confirmed,
.actionDonation .actionDonation-stats .actionDonation-donators,
.actionDonation .actionDonation-stats .actionDonation-daysLeft {
  width: 20%;
}
.actionDonation .actionDonation-stats .actionDonation-contributed-title {
  font-size: 41px;
  color: #147F74;
  text-align: left;
  font-weight: bold;
  width: 100%;
}
@media only screen and (max-width: 992px) {
  .actionDonation .actionDonation-stats .actionDonation-contributed-title {
    font-size: 29px;
  }
}
.actionDonation .actionDonation-stats .actionDonation-confirmed-title,
.actionDonation .actionDonation-stats .actionDonation-donators-title,
.actionDonation .actionDonation-stats .actionDonation-daysLeft-title {
  font-size: 29px;
  color: #273941;
  text-align: left;
  font-weight: 500;
  width: 100%;
}
.actionDonation .actionDonation-stats .actionDonation-contributed-subtitle,
.actionDonation .actionDonation-stats .actionDonation-confirmed-subtitle,
.actionDonation .actionDonation-stats .actionDonation-donators-subtitle,
.actionDonation .actionDonation-stats .actionDonation-daysLeft-subtitle {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  width: 100%;
}
@media only screen and (max-width: 992px) {
  .actionDonation .actionDonation-stats .actionDonation-contributed-subtitle,
  .actionDonation .actionDonation-stats .actionDonation-confirmed-subtitle,
  .actionDonation .actionDonation-stats .actionDonation-donators-subtitle,
  .actionDonation .actionDonation-stats .actionDonation-daysLeft-subtitle {
    font-size: 13px;
  }
}
.actionDonation .actionDonation-info {
  width: 100%;
  margin-top: 26px;
}
.actionDonation .actionDonation-info .actionDonation-feature {
  margin-bottom: 12px;
}
.actionDonation .actionDonation-info .actionDonation-feature img {
  margin-left: 6px;
  margin-right: 8px;
}
.actionDonation .actionDonation-info .actionDonation-feature span {
  font-size: 15px;
  color: #7b8989;
  text-align: left;
}
.actionDonation .actionDonation-info .actionDonation-donationWays {
  display: inline-block;
  width: 100%;
}
.actionDonation .actionDonation-info .actionDonation-donationWays span {
  display: inline-block;
  width: 100%;
  font-size: 15px;
  color: #7b8989;
  text-align: left;
  margin-bottom: 12px;
}
.actionDonation .actionDonation-info .actionDonation-donationWays .actionDonation-donationWay {
  display: inline-flex;
}
.actionDonation .actionDonation-info .actionDonation-donationWays .actionDonation-donationWay img {
  margin-right: 24px;
}
.actionCollaboration {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
.actionCollaboration .actionCollaboration-title {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.15px;
  text-align: right;
}
.actionCollaboration .actionCollaboration-title.canceled {
  color: #de0a0a;
}
.actionCollaboration .actionCollaboration-title.pending {
  color: #ff9500;
}
.actionCollaboration .actionCollaboration-title.accepted {
  color: #00b46e;
}
.actionCollaboration .actionCollaboration-title.rejected {
  color: #ff4545;
}
.actionCollaboration .actionCollaboration-text {
  font-size: 15px;
  letter-spacing: 0.15px;
  color: #7b8989;
  text-align: right;
}
.actionCollaboration-button {
  padding: 0 22px;
  height: 48px;
  font-size: 17px;
  margin-left: 12px;
}
.actionCollaboration-button img {
  margin-right: 8px;
  margin-top: -1px;
}
.actionCollaboration-button svg {
  margin-right: 8px;
  margin-bottom: -3px;
}
.initiativeTabs {
  overflow: visible;
}
.Initiative__share {
  opacity: 1;
}
.Initiative__share[disabled] {
  opacity: 0;
}
.actionQuestions {
  width: 100%;
  margin-top: 42px;
  margin-bottom: 42px;
}
.actionQuestions-title {
  font-size: 17px;
  color: #7b8989;
  text-align: left;
  letter-spacing: 0.01em;
  width: 100%;
  margin-bottom: 12px;
}
.actionQuestions-item {
  width: 100%;
  font-size: 15px;
  color: #273941;
  text-align: left;
  letter-spacing: 0.01em;
  line-height: 22px;
}
.InitiativeDetail-donation-info ul {
  list-style-type: circle;
}
.InitiativeDetail__card {
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(123, 137, 137, 0.1);
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  padding: 18px;
}
.InitiativeDetail__card-title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7B8989;
  margin-bottom: 6px;
}
.InitiativeDetail__card-title-margin {
  margin-top: 12px;
}
.InitiativeDetail__card-dropdown.CustomSelect .CustomSelect-button {
  height: 46px;
}
.InitiativeDetail__title {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.13px;
  color: #7B8989;
  margin: 35px 0px 12px 0px;
}
.sdgs-popover-content .sdg {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
